export const checkAssetAccess = (assets, checkAccessLink) => {
    let passed = true;
    assets.map(async (currentAsset) => {
        const response = await fetch(checkAccessLink, {
            method: 'POST',
            body: JSON.stringify({
                asset: currentAsset.id,
            }),
        });
        if (response.status !== 200) {
            passed = false;
        }
    });
    return passed;
};
