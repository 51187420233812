import AbstractController from './abstract/AbstractController';
import { hideErrors, showError, submitFormAsRequest } from '../helpers/form-helper';
export default class default_1 extends AbstractController {
    static targets = ['inputTitle', 'inputDueDate', 'editContainer', 'viewContainer'];
    connect = () => {
        this.formElement = this.element.querySelector('[name="workflow_base_property_change"]');
        this.requiredErrorMessage = this.inputTitleTarget.dataset.required || '';
        this.inputAccessibilityCheckUserTarget = document.getElementById('workflow_base_property_change_accessibilityCheckUser_autocomplete');
    };
    toggleMode = () => {
        this.editContainerTarget.classList.toggle('d-none');
        this.viewContainerTarget.classList.toggle('d-none');
    };
    clickSubmitButton = () => {
        hideErrors(this.element);
        let hasErrors = false;
        if (!this.inputTitleTarget.value.trim()) {
            showError(this.inputTitleTarget, this.requiredErrorMessage);
            hasErrors = true;
        }
        if (this.hasInputDueDateTarget) {
            if (this.inputDueDateTarget._flatpickr.selectedDates.length < 1) {
                showError(this.inputDueDateTarget, this.requiredErrorMessage);
                hasErrors = true;
            }
        }
        if (this.inputAccessibilityCheckUserTarget &&
            this.inputAccessibilityCheckUserTarget.tomselect &&
            !this.inputAccessibilityCheckUserTarget.tomselect?.getValue()) {
            showError(this.inputAccessibilityCheckUserTarget, this.requiredErrorMessage);
            hasErrors = true;
        }
        if (!hasErrors) {
            this.handleFormSubmit();
        }
    };
    handleFormSubmit = async () => {
        if (this.formElement === null) {
            return;
        }
        const response = await submitFormAsRequest(this.formElement);
        if (response.status !== 200) {
            return false;
        }
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        urlParams.append('success', '1');
        window.location.search = urlParams.toString();
    };
}
