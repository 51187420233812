import { Modal } from 'bootstrap';
import AbstractController from './abstract/AbstractController';
import { submitFormAsRequest } from '../helpers/form-helper';
export default class default_1 extends AbstractController {
    static targets = ['form', 'title', 'description', 'token', 'submitButton'];
    connect = () => {
        this.modal = new Modal(this.element);
    };
    open = (content, form, responseCallback) => {
        this.titleTarget.innerText = content.title;
        this.descriptionTarget.innerText = content.description;
        if (form) {
            this.formTarget.action = form.path;
            this.tokenTarget.value = form.token;
            if (form.apiRequest) {
                this.submitButtonTarget.type = 'button';
                this.submitButtonTarget.addEventListener('click', this.submit);
            }
            else {
                this.submitButtonTarget.type = 'submit';
                this.submitButtonTarget.removeEventListener('click', this.submit);
            }
            this.responseCallback = responseCallback;
        }
        else if (responseCallback) {
            this.submitButtonTarget.type = 'button';
            this.submitButtonTarget.onclick = null;
            this.submitButtonTarget.addEventListener('click', () => responseCallback(), { once: true });
        }
        this.modal.show();
    };
    submit = async () => {
        const response = await submitFormAsRequest(this.formTarget);
        if (this.responseCallback) {
            this.responseCallback(response);
        }
    };
}
