import AbstractController from './abstract/AbstractController';
import TomSelect from 'tom-select';
import _ from 'lodash';
import FroalaEditor from 'froala-editor';
export default class default_1 extends AbstractController {
    static targets = ['component', 'list', 'removeButton', 'moveUpButton', 'moveDownButton'];
    static outlets = ['clipboard'];
    connect = () => {
        this.updateComponentButtons();
        this.template = this.componentTargets[0].cloneNode(true);
        this.nextIndex = this.componentTargets.length;
    };
    updateComponentButtons = () => {
        for (const button of this.moveUpButtonTargets
            .concat(this.moveDownButtonTargets)
            .concat(this.removeButtonTargets)) {
            button.disabled = false;
        }
        if (!_.isEmpty(this.moveUpButtonTargets) && !_.isEmpty(this.moveDownButtonTargets)) {
            this.moveUpButtonTargets[0].disabled = true;
            this.moveDownButtonTargets[this.moveDownButtonTargets.length - 1].disabled = true;
        }
        setTimeout(() => {
            this.removeButtonTargets[0].disabled = this.componentTargets.length === 1;
        }, 500);
    };
    updateComponentList = (list) => {
        this.listTarget.innerHTML = '';
        for (const component of list) {
            this.listTarget.append(component);
        }
        this.updateComponentButtons();
        for (const component of this.componentTargets) {
            this.updateComponentIndex(component);
        }
    };
    addComponent = (e) => {
        const clonedComponent = this.template.cloneNode(true);
        clonedComponent.id = Date.now().toString();
        const clonedElement = this.listTarget.appendChild(clonedComponent);
        this.updateComponentIndex(clonedElement);
        for (const select of clonedComponent.querySelectorAll('select')) {
            this.setupTomselect(select);
        }
        for (const textArea of clonedComponent.querySelectorAll('textarea')) {
            this.initNewFroalaEditor(textArea);
        }
        for (const input of clonedComponent.querySelectorAll('input, textarea')) {
            input.value = '';
        }
        this.updateComponentButtons();
        if (e.currentTarget.hasAttribute('data-open-clipboard')) {
            this.clipboardOutlet.open(clonedComponent.id, clonedElement.querySelector('[data-clipboard-field-target=input]'), true);
        }
    };
    removeComponent = (event) => {
        const index = this.removeButtonTargets.indexOf(event.currentTarget);
        this.componentTargets[index].remove();
        this.updateComponentButtons();
    };
    removeComponentByButton = (button) => {
        const index = this.removeButtonTargets.indexOf(button);
        this.componentTargets[index].remove();
        this.updateComponentButtons();
    };
    removeLastComponent = () => {
        const index = this.componentTargets.length - 1;
        this.componentTargets[index].remove();
        this.updateComponentButtons();
    };
    removeComponentById = (componentId) => {
        document.getElementById(componentId)?.remove();
    };
    moveComponentUp = (event) => {
        const index = this.moveUpButtonTargets.indexOf(event.currentTarget);
        this.moveComponent(index, -1);
    };
    moveComponentDown = (event) => {
        const index = this.moveDownButtonTargets.indexOf(event.currentTarget);
        this.moveComponent(index, 1);
    };
    moveComponent = (index, direction) => {
        const list = this.componentTargets;
        list[index] = this.componentTargets[index + direction];
        list[index + direction] = this.componentTargets[index];
        this.updateComponentList(list);
    };
    updateComponentIndex = (component) => {
        const index = this.nextIndex;
        this.nextIndex++;
        const attributesToUpdate = [
            'id',
            'name',
            'aria-controls',
            'for',
            'aria-labelledby',
            'data-bs-target',
            'data-model',
        ];
        for (const attribute of attributesToUpdate) {
            const elements = component.querySelectorAll(`[${attribute}]`);
            for (const element of elements) {
                element.setAttribute(attribute, element.getAttribute(attribute).replace(/\d+/, index.toString()));
            }
        }
    };
    initNewFroalaEditor(element) {
        element.nextElementSibling?.remove();
        element.value = '';
        new FroalaEditor('#' + element.id, {
            key: 'iTB2xC5C4C3B2C1G3E1pZGCTRSAPJWTLPLZHTQQb1JGZxE2F2G2F1B10B2A1E6C1A1==',
            language: 'de',
            toolbarSticky: true,
            toolbarStickyOffset: 64,
            attribution: false,
            listAdvancedTypes: false,
            inlineClasses: {
                'fr-class-inline': 'inline',
                'fr-class-red-highlighted': 'Rot',
            },
            paragraphStyles: {
                'fr-class-red-highlighted': 'Rot',
            },
            linkList: [
                {
                    text: 'SBB',
                    href: 'https://sbb.ch',
                    target: '_blank',
                },
                {
                    text: 'URL news',
                    href: 'news://',
                    target: '_blank',
                },
                {
                    text: 'URL https',
                    href: 'https://',
                    target: '_blank',
                },
                {
                    text: 'URL http',
                    href: 'http://',
                    target: '_blank',
                },
                {
                    text: 'Mail',
                    href: 'mailto://',
                },
                {
                    text: 'Telefon',
                    href: 'tel://',
                },
            ],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            events: {
                'popups.show.link.insert': function () {
                    if (!this.popups.get('link.insert')[0].querySelector('[id^="fr-link-target"]')) {
                        return;
                    }
                    this.popups.get('link.insert')[0].querySelector('[id^="fr-link-target"]').checked = true;
                },
            },
            pastePlain: true,
            toolbarButtons: [
                'bold',
                'italic',
                'subscript',
                'superscript',
                'underline',
                'strikeThrough',
                'clearFormatting',
                'inlineClass',
                '|',
                'paragraphFormat',
                'paragraphStyle',
                '|',
                'insertLink',
                'insertTable',
                'formatOL',
                'formatUL',
                '|',
                'insertHR',
                'fullscreen',
                'html',
                '|',
                'undo',
                'redo',
            ],
        });
    }
    setupTomselect = (element) => {
        element.nextElementSibling?.remove();
        element.id = '';
        element.className = '';
        new TomSelect(element, { create: true }).clear();
        if (element.dataset.tomselectValidOptions && element.tomselect) {
            const validOptions = JSON.parse(element.dataset.tomselectValidOptions);
            for (const option in element.tomselect.options) {
                if (!validOptions.includes(option)) {
                    element.tomselect.removeOption(option);
                }
            }
        }
    };
}
