import AbstractController from './abstract/AbstractController';
import { getNotificationToast } from '../helpers/notification-helper';
import translate from '../helpers/translation-helper';
export default class default_1 extends AbstractController {
    static targets = ['versionList'];
    clickSubmit = async () => {
        this.formElement = document.querySelector('[data-identifier="workflow-main-form"]');
        if (this.formElement === null) {
            return;
        }
        const formdata = new FormData(this.formElement);
        // Extract the workflow ID from the URL and use it to create a new snapshot
        const url = window.location.href;
        const regex = /tide\/(\d+)\//;
        const reversedUrl = url.split('').reverse().join('');
        const match = reversedUrl.match(regex);
        if (!match) {
            return;
        }
        const id = match[1].split('').reverse().join('');
        const response = await fetch('/workflow/' + id + '/snapshot', {
            method: 'POST',
            body: formdata,
        });
        if (response.status !== 200) {
            return;
        }
        const newVersion = await response.json();
        this.appendVersionToList(newVersion);
        this.changeListCounter();
        getNotificationToast('save-success')?.show();
    };
    appendVersionToList = async (newVersion) => {
        this.versionListTarget.insertAdjacentHTML('beforeend', newVersion.newVersion);
    };
    changeListCounter = async () => {
        const count = this.versionListTarget.childElementCount;
        const accordionHeader = document.querySelector('.accordion-button.sbb-accordion__button.p-2.ps-4');
        if (!accordionHeader) {
            return;
        }
        accordionHeader.textContent =
            (await translate('sidebar.workflow_versions_history', 'workflow')) + ' (' + count + ')';
    };
}
