import AbstractController from './abstract/AbstractController';
import tippy from 'tippy.js';
export default class default_1 extends AbstractController {
    static targets = ['step'];
    connect = () => {
        tippy(this.stepTargets, {
            theme: 'sbb',
            appendTo: 'parent',
        });
    };
}
