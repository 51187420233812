import { Modal, Toast } from 'bootstrap';
import AbstractController from './AbstractController';
export default class ModalController extends AbstractController {
    static targets = ['submitModalButton'];
    enterBlockerElements = [];
    initialize = () => {
        this.modal = new Modal(this.element);
        const successNotificationElement = document.querySelector(`[data-notifications-for=${this.element.id}] .toast.-success`);
        if (successNotificationElement) {
            this.successNotification = new Toast(successNotificationElement);
        }
        //listen to enter
        document.addEventListener('keydown', (event) => {
            if (!this.hasSubmitModalButtonTarget || event.key !== 'Enter' || !this.element.classList.contains('show')) {
                return;
            }
            const isAllowedToEnter = document.activeElement ? !this.enterBlockerElements.includes(document.activeElement) : true;
            if (isAllowedToEnter) {
                this.submitModalButtonTarget.click();
            }
        });
    };
    disconnect = () => {
        this.modal.dispose();
    };
}
