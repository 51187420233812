import { initTomSelect } from '../helpers/tomselect-helper';
import AbstractController from './abstract/AbstractController';
export default class extends AbstractController {
    initialize = () => {
        this.instance = initTomSelect(this.element);
        this.element.dispatchEvent(new Event('tomselect-ready'));
    };
    disconnect = () => {
        this.instance.destroy();
    };
}
