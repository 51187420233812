import ModalController from './abstract/ModalController';
export default class default_1 extends ModalController {
    static targets = ['metaInformationWrapper', 'metaInformationCheckbox', 'guidelineInputWrapper'];
    selectionChanged = (e) => {
        if (e.currentTarget instanceof HTMLElement && e.currentTarget?.id == 'word_export_wordExportType_0') {
            this.metaInformationWrapperTarget.classList.remove('d-none');
            this.guidelineInputWrapperTarget.classList.add('d-none');
        }
        else {
            this.guidelineInputWrapperTarget.classList.remove('d-none');
            this.metaInformationWrapperTarget.classList.add('d-none');
            this.metaInformationCheckboxTarget.checked = false;
        }
    };
}
