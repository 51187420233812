import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = [
        'input',
        'observer',
        'deleteButton',
        'addButton',
        'list',
        'text',
        'template',
        'observerCount',
        'workflowId',
        'switch',
        'toggleInput',
    ];
    add = async () => {
        const observerId = this.inputTarget.value;
        const workflowId = this.workflowIdTarget.dataset.workflowId ?? '0';
        const formData = new FormData();
        formData.append('observerId', observerId);
        const response = await fetch('/observer/' + workflowId, {
            method: 'POST',
            body: formData,
        });
        if (response.ok) {
            const body = await response.json();
            const { id, fullname } = body;
            const observerExists = this.observerTargets.some(observer => observer.dataset.observerId === String(id));
            if (observerExists) {
                throw new Error('Observer already exists');
            }
            else {
                const index = this.observerTargets.length;
                const clonedComponent = this.templateTarget.content.firstElementChild?.cloneNode(true);
                clonedComponent.dataset.observerId = id;
                this.listTarget.appendChild(clonedComponent);
                this.textTargets[index].innerText = fullname;
                this.inputTarget.tomselect?.clear();
                this.updateObserverCount(this.observerTargets.length);
            }
        }
    };
    delete = async (event) => {
        const index = this.deleteButtonTargets.indexOf(event.currentTarget);
        const observerId = this.observerTargets[index].dataset.observerId ?? '0';
        const workflowId = this.workflowIdTarget.dataset.workflowId ?? '0';
        const response = await fetch('/observer/' + workflowId + '/' + observerId, {
            method: 'DELETE',
        });
        if (response.ok) {
            this.observerTargets[index].remove();
            this.updateObserverCount(this.observerTargets.length);
            if (this.toggleInputTarget.dataset.propOne === observerId) {
                this.toggleInputTarget.checked = false;
            }
        }
    };
    updateObserverCount(count) {
        this.observerCountTarget.innerText = String(count);
    }
    toggleInput = async () => {
        const observerId = this.toggleInputTarget.dataset.propOne ?? '';
        const workflowId = parseInt(this.toggleInputTarget.dataset.propTwo);
        if (this.toggleInputTarget.checked) {
            const formData = new FormData();
            formData.append('observerId', observerId);
            const response = await fetch('/observer/' + workflowId, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                const body = await response.json();
                const { id, fullname } = body;
                const observerExists = this.observerTargets.some(observer => observer.dataset.observerId === String(id));
                if (observerExists) {
                    throw new Error('Observer already exists');
                }
                else {
                    const index = this.observerTargets.length;
                    const clonedComponent = this.templateTarget.content.firstElementChild?.cloneNode(true);
                    clonedComponent.dataset.observerId = id;
                    this.listTarget.appendChild(clonedComponent);
                    this.textTargets[index].innerText = fullname;
                    this.updateObserverCount(this.observerTargets.length);
                }
            }
        }
        else {
            const observerId = this.toggleInputTarget.dataset.propOne ?? '';
            const observerElement = this.observerTargets.find(observer => observer.dataset.observerId === observerId);
            if (observerElement) {
                const index = this.observerTargets.indexOf(observerElement);
                const workflowId = this.workflowIdTarget.dataset.workflowId ?? '0';
                const response = await fetch('/observer/' + workflowId + '/' + observerId, {
                    method: 'DELETE',
                });
                if (response.ok) {
                    this.observerTargets[index].remove();
                    this.updateObserverCount(this.observerTargets.length);
                }
            }
        }
    };
}
