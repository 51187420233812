import './styles/app.scss';
import '../assets/plugins/tomselect/disable-keyboard-interactions';
import '../assets/plugins/tomselect/sbb-multiselect';
import '../assets/plugins/tomselect/sortable';
import '../assets/plugins/froala/comments-extension';
// start the Stimulus application
import './bootstrap';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { getEnv } from './helpers/env';
const env = getEnv();
if (['int', 'next', 'live'].includes(env)) {
    Sentry.init({
        dsn: 'https://029947be4d6549869009cb8d38ea3e43@sentry.intersim.ch/39',
        environment: env,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.2,
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications',
        ],
    });
}
// @ts-ignore
import 'bootstrap';
