import { copyToClipboard } from '../helpers/clipboard-helper';
import AbstractController from './abstract/AbstractController';
import { getNotificationToast } from '../helpers/notification-helper';
export default class extends AbstractController {
    copySuccessNotification;
    connect() {
        this.copySuccessNotification = getNotificationToast('copy-success');
    }
    copy = (e) => {
        const button = e.currentTarget;
        const link = button.previousElementSibling;
        if (!link) {
            return;
        }
        if (this.copySuccessNotification) {
            this.copySuccessNotification.show();
            setTimeout(() => {
                if (this.copySuccessNotification) {
                    this.copySuccessNotification.hide();
                }
            }, 5000);
        }
        copyToClipboard(link.href);
    };
}
