import AbstractController from '../abstract/AbstractController';
import { passesFilter } from '../../helpers/filter-helper';
import { DateTime } from 'luxon';
import { initTomSelect } from '../../helpers/tomselect-helper';
export default class default_1 extends AbstractController {
    static targets = [
        'textFilter',
        'dateFilter',
        'stepFilter',
        'noResultsNotification',
        'removeWorkflowButton'
    ];
    connect() {
        this.workflowElements = this.element.querySelectorAll('[data-dashboard-workflow]');
        this.workflowTypeElements = this.element.querySelectorAll('[data-dashboard-workflow-type]');
        this.workflows = [...this.workflowElements].map(element => {
            return {
                ...JSON.parse(element.dataset.dashboardWorkflow),
                element: element,
            };
        });
        this.initFilters();
        this.initSorting();
    }
    initFilters = () => {
        this.activeFilters = [() => true, () => true, () => true];
        this.textFilterTarget.addEventListener('input', e => {
            this.updateTextFilter(e.target.value);
        });
        this.dateFilterTarget.addEventListener('input', () => {
            this.updateDateFilter(this.dateFilterTarget._flatpickr.selectedDates);
        });
        const stepFilter = initTomSelect(this.stepFilterTarget, { plugins: ['sbb-multiselect'] });
        stepFilter.wrapper.onclick = () => {
            stepFilter.control.click();
        };
        stepFilter.on('change', (steps) => this.updateStepFilter(steps));
        this.applyFilters();
    };
    updateTextFilter = (searchText) => {
        if (searchText === '') {
            this.activeFilters[0] = () => true;
        }
        else {
            this.activeFilters[0] = workflow => workflow.name.toLowerCase().includes(searchText.toLowerCase());
        }
        this.applyFilters();
    };
    updateDateFilter = (dates) => {
        if (dates[0]) {
            const startDate = DateTime.fromJSDate(dates[0]);
            if (dates[1]) {
                const endDate = DateTime.fromJSDate(dates[1]);
                this.activeFilters[1] = workflow => DateTime.fromISO(workflow.date).startOf('day') >= startDate.startOf('day') &&
                    DateTime.fromISO(workflow.date).startOf('day') <= endDate.startOf('day');
            }
            else {
                this.activeFilters[1] = workflow => DateTime.fromISO(workflow.date).startOf('day') >= startDate.startOf('day');
            }
        }
        else {
            this.activeFilters[1] = () => true;
        }
        this.applyFilters();
    };
    updateStepFilter = (steps) => {
        if (steps.length < 1) {
            this.activeFilters[2] = () => true;
        }
        else {
            this.activeFilters[2] = workflow => steps.includes(workflow.step.name.toString());
        }
        this.applyFilters();
    };
    applyFilters = () => {
        if (this.workflows.length < 1) {
            return;
        }
        for (const workflow of this.workflows) {
            workflow.element.classList.toggle('d-none', !passesFilter(workflow, this.activeFilters));
        }
        for (const workflowType of this.workflowTypeElements) {
            const workflows = workflowType.querySelectorAll('[data-dashboard-workflow]');
            if (!workflows) {
                return;
            }
            workflowType.classList.toggle('d-none', Array.from(workflows).every(workflow => workflow.classList.contains('d-none')));
        }
        this.noResultsNotificationTarget.classList.toggle('d-none', !Array.from(this.workflowTypeElements).every(workflowType => workflowType.classList.contains('d-none')));
    };
    initSorting = () => {
        this.activeSortings = Array.from(this.workflowTypeElements).map(wt => {
            return { table: wt.dataset.dashboardWorkflowType, key: 'date', direction: 1 };
        });
        for (const table of this.workflowTypeElements) {
            const headers = table.querySelectorAll('[data-sorting]');
            for (const header of headers) {
                header.addEventListener('click', () => this.toggleSorting(table, header));
            }
        }
        this.applySortings(this.activeSortings);
    };
    toggleSorting = (table, header) => {
        const activeSorting = this.activeSortings.find(s => s.table === table.dataset.dashboardWorkflowType);
        if (!activeSorting) {
            this.activeSortings.push({
                table: table.dataset.dashboardWorkflowType,
                key: header.dataset.sorting,
                direction: 1,
            });
        }
        else if (activeSorting.key !== header.dataset.sorting) {
            activeSorting.key = header.dataset.sorting;
            activeSorting.direction = 1;
        }
        else {
            if (activeSorting.key === header.dataset.sorting) {
                activeSorting.direction = ((activeSorting.direction + 2) % 3) - 1;
            }
        }
        this.applySortings(this.activeSortings);
    };
    applySortings = (sortings) => {
        for (const table of this.workflowTypeElements) {
            for (const sortIcon of table.querySelectorAll(`[data-sorting] .arrow-icon`)) {
                sortIcon.classList.remove('active');
            }
            const sorting = sortings.find(s => s.table === table.dataset.dashboardWorkflowType);
            if (sorting) {
                for (const sortIcon of table.querySelectorAll(`[data-sorting="${sorting.key}"] .arrow-icon`)) {
                    sortIcon.classList.toggle('active', sorting.direction !== 0);
                    sortIcon.classList.toggle('rotate', sorting.direction === -1);
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let valueFn;
                switch (sorting.key) {
                    case 'date':
                        valueFn = (value) => DateTime.fromISO(value).toSeconds().toString();
                        break;
                    case 'step':
                        valueFn = (value) => value.order;
                        break;
                    default:
                        valueFn = (value) => value;
                }
                const workflowContainer = table.querySelector('.container-fluid');
                const sortFn = (a, b) => (valueFn(a[sorting.key]) <
                    valueFn(b[sorting.key])
                    ? 1
                    : -1) * sorting.direction;
                const workflows = this.workflows.filter(w => workflowContainer.contains(w.element));
                workflows.sort(sortFn);
                for (const workflow of workflows) {
                    workflowContainer.append(workflow.element);
                }
            }
        }
    };
}
