import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['videoFormatTargetPlatformId', 'screenPreview', 'aspectRatio'];
    async toggleVideoFormatTargetPlatformCheckState() {
        const formatIdString = this.data.get('checkboxId');
        if (!formatIdString) {
            return;
        }
        const formatId = parseFloat(formatIdString);
        const videoFormatTargetPlatformCheckbox = document.getElementById(`create_videoFormatTargetPlatform_${formatId}`);
        videoFormatTargetPlatformCheckbox.checked = !videoFormatTargetPlatformCheckbox.checked;
        this.screenPreviewTarget.classList.toggle('-checked', videoFormatTargetPlatformCheckbox.checked);
        this.aspectRatioTarget.classList.toggle('-checked', videoFormatTargetPlatformCheckbox.checked);
        const videoFormatCheckboxes = document.querySelectorAll('input[type="checkbox"][id*="create_videoFormatTargetPlatform_"]:checked');
        const selectedVideoFormatIds = Array.from(videoFormatCheckboxes).map(checkbox => parseInt(checkbox.value));
        const response = await fetch('/workflow/video/selected-video-formats-preview/', {
            method: 'POST',
            body: JSON.stringify({
                videoFormatIds: selectedVideoFormatIds,
            }),
        });
        const htmlPreview = JSON.parse(await response.text()).preview;
        const formatsPreview = document.getElementById('selectedVideoFormatsBox');
        formatsPreview.innerHTML = htmlPreview;
    }
}
