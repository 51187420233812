import AbstractController from './abstract/AbstractController';
import { checkForNotifications } from '../helpers/notification-helper';
export default class default_1 extends AbstractController {
    static targets = ['tabScroll'];
    connect() {
        checkForNotifications();
    }
    scrollToTab = (targetTab) => {
        if (!targetTab) {
            return;
        }
        const scroll = targetTab.offsetLeft;
        const offset = this.tabScrollTarget.firstElementChild.clientWidth;
        this.tabScrollTarget.scrollTo({
            left: scroll - offset,
            behavior: 'smooth',
        });
        targetTab.scrollIntoView({ inline: 'center' });
        targetTab.click();
    };
    scrollLeft = () => {
        const activeTab = this.element.querySelector('.nav-link.active');
        if (!activeTab) {
            return;
        }
        this.scrollToTab(activeTab.parentElement?.previousElementSibling?.querySelector('.nav-link'));
    };
    scrollRight = () => {
        const activeTab = this.element.querySelector('.nav-link.active');
        if (!activeTab) {
            return;
        }
        this.scrollToTab(activeTab.parentElement?.nextElementSibling?.querySelector('.nav-link'));
    };
}
