import AbstractController from './abstract/AbstractController';
import TomSelect from 'tom-select';
export default class default_1 extends AbstractController {
    static targets = ['select', 'customInput'];
    static outlets = ['delete-modal', 'collection'];
    connect = () => {
        this.select = this.selectTarget.tomselect || new TomSelect(this.selectTarget, {});
        this.select.on('change', this.onDescriptionChange);
        this.customInputTarget.addEventListener('blur', this.updateCustomDescription);
        this.toggleCustomInput(this.customInputTarget.value !== '');
    };
    onDescriptionChange = (e) => {
        if (e === 'custom') {
            this.toggleCustomInput(true);
            this.customInputTarget.focus();
        }
        else {
            this.toggleCustomInput(false);
        }
    };
    toggleCustomInput = (show) => {
        this.customInputTarget.classList.toggle('d-none', !show);
    };
    updateCustomDescription = () => {
        const customValue = this.customInputTarget.value;
        for (const option of this.select.input.children) {
            option.removeAttribute('selected');
        }
        const customOption = document.createElement('option');
        customOption.value = customValue;
        customOption.setAttribute('selected', 'selected');
        this.select.input.append(customOption);
    };
    removeTextBlock = (event) => {
        const button = event.currentTarget;
        this.collectionOutlet.removeComponentByButton(button);
    };
}
