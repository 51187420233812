import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['removeWorkflowButton'];
    removeWorkflow = async (event) => {
        const button = event.currentTarget;
        const { path, token } = button.dataset;
        if (!(path && token)) {
            return;
        }
        const url = path;
        const formData = new FormData();
        formData.append('_token', token);
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            return;
        }
        window.location.reload();
    };
}
