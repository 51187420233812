import AbstractController from './abstract/AbstractController';
import TomSelect from 'tom-select';
import _ from 'lodash';
import FroalaEditor from 'froala-editor';
import translate from '../helpers/translation-helper';
export default class default_1 extends AbstractController {
    static targets = ['component', 'list', 'removeButton', 'moveUpButton', 'moveDownButton'];
    connect = () => {
        this.updateComponentButtons();
        this.template = this.componentTargets[0].cloneNode(true);
        this.nextIndex = this.componentTargets.length;
        this.updateSceneTitle(false);
    };
    updateComponentButtons = () => {
        for (const button of this.moveUpButtonTargets
            .concat(this.moveDownButtonTargets)
            .concat(this.removeButtonTargets)) {
            button.disabled = false;
        }
        if (!_.isEmpty(this.moveUpButtonTargets) && !_.isEmpty(this.moveDownButtonTargets)) {
            this.moveUpButtonTargets[0].disabled = true;
            this.moveDownButtonTargets[this.moveDownButtonTargets.length - 1].disabled = true;
        }
        this.removeButtonTargets[0].disabled = this.componentTargets.length === 1;
    };
    updateComponentList = (list) => {
        this.listTarget.innerHTML = '';
        for (const component of list) {
            this.listTarget.append(component);
        }
        this.updateComponentButtons();
        for (const component of this.componentTargets) {
            this.updateComponentIndex(component);
        }
    };
    addComponent = () => {
        const clonedComponent = this.template.cloneNode(true);
        const clonedElement = this.listTarget.appendChild(clonedComponent);
        const clonedElementFrontiyfy = clonedElement.querySelector('[data-scene-index]');
        const dateTime = Date.now().toString();
        if (clonedElementFrontiyfy) {
            clonedElementFrontiyfy.setAttribute('data-scene-index', dateTime);
        }
        const clonedElementImageField = clonedElement.querySelector('[data-header-image-field]');
        if (clonedElementImageField) {
            clonedElementImageField.setAttribute('data-header-image-field', 'value-' + dateTime);
        }
        const clonedAssetWrapper = clonedElement.getElementsByClassName('asset-wrapper')[0];
        if (clonedAssetWrapper) {
            clonedAssetWrapper.classList.add('d-none');
        }
        const clonedImageButtons = Array.from(clonedElement.getElementsByTagName('button'));
        clonedImageButtons.forEach(element => {
            element.classList.remove('d-none');
        });
        this.updateComponentIndex(clonedElement);
        for (const select of clonedComponent.querySelectorAll('select')) {
            this.setupTomselect(select);
        }
        for (const textArea of clonedComponent.querySelectorAll('textarea')) {
            this.initNewFroalaEditor(textArea);
        }
        for (const input of clonedComponent.querySelectorAll('input, textarea')) {
            input.value = '';
        }
        this.updateComponentButtons();
        this.updateSceneTitle(true);
    };
    removeComponent = async (event) => {
        const index = this.removeButtonTargets.indexOf(event.currentTarget);
        if (confirm(await translate('video.sure', 'workflow'))) {
            this.componentTargets[index].remove();
            this.updateComponentButtons();
            this.updateSceneTitle(false);
        }
    };
    removeLastComponent = () => {
        const index = this.componentTargets.length - 1;
        this.componentTargets[index].remove();
        this.updateComponentButtons();
    };
    moveComponentUp = (event) => {
        const index = this.moveUpButtonTargets.indexOf(event.currentTarget);
        this.moveComponent(index, -1);
    };
    moveComponentDown = (event) => {
        const index = this.moveDownButtonTargets.indexOf(event.currentTarget);
        this.moveComponent(index, 1);
    };
    updateSceneTitle = (newElementAdded) => {
        let index = 0;
        this.componentTargets.forEach(elem => {
            index++;
            const sceneTitle = elem.querySelector('[data-scene-target="sceneTitle"]');
            const sceneNumberInput = elem.querySelector('input');
            if (!(sceneTitle && sceneNumberInput)) {
                return;
            }
            if (sceneTitle.textContent) {
                const containsChars = /\D/.test(sceneTitle.textContent);
                if (!containsChars) {
                    sceneTitle.textContent = index.toString();
                    sceneNumberInput.value = index.toString();
                }
                if (newElementAdded && index == this.componentTargets.length) {
                    sceneTitle.textContent = index.toString();
                    sceneNumberInput.value = index.toString();
                }
            }
            else {
                sceneTitle.textContent = index.toString();
                sceneNumberInput.value = index.toString();
            }
            sceneTitle.id = `scene-number-${index}`;
        });
    };
    moveComponent = (index, direction) => {
        const list = this.componentTargets;
        list[index] = this.componentTargets[index + direction];
        list[index + direction] = this.componentTargets[index];
        this.updateComponentList(list);
        this.updateSceneTitle(false);
    };
    updateComponentIndex = (component) => {
        const index = this.nextIndex;
        this.nextIndex++;
        const attributesToUpdate = [
            'id',
            'name',
            'aria-controls',
            'for',
            'aria-labelledby',
            'data-bs-target',
            'data-model',
        ];
        for (const attribute of attributesToUpdate) {
            const elements = component.querySelectorAll(`[${attribute}]`);
            for (const element of elements) {
                element.setAttribute(attribute, element.getAttribute(attribute).replace(/\d+/, index.toString()));
            }
        }
    };
    initNewFroalaEditor(element) {
        element.nextElementSibling?.remove();
        element.value = '';
        new FroalaEditor('#' + element.id, {
            key: 'iTB2xC5C4C3B2C1G3E1pZGCTRSAPJWTLPLZHTQQb1JGZxE2F2G2F1B10B2A1E6C1A1==',
            language: 'de',
            toolbarSticky: true,
            toolbarStickyOffset: 64,
            attribution: false,
            listAdvancedTypes: false,
            inlineClasses: {
                'fr-class-inline': 'inline',
                'fr-class-red-highlighted': 'Rot',
            },
            paragraphStyles: {
                'fr-class-red-highlighted': 'Rot',
            },
            linkList: [
                {
                    text: 'SBB',
                    href: 'https://sbb.ch',
                    target: '_blank',
                },
                {
                    text: 'URL news',
                    href: 'news://',
                    target: '_blank',
                },
                {
                    text: 'URL https',
                    href: 'https://',
                    target: '_blank',
                },
                {
                    text: 'URL http',
                    href: 'http://',
                    target: '_blank',
                },
                {
                    text: 'Mail',
                    href: 'mailto://',
                },
                {
                    text: 'Telefon',
                    href: 'tel://',
                },
            ],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            events: {
                'popups.show.link.insert': function () {
                    this.popups.get('link.insert')[0].querySelector('#fr-link-target-1').checked = true;
                },
            },
            pastePlain: true,
            toolbarButtons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'clearFormatting',
                'inlineClass',
                '|',
                'paragraphFormat',
                'paragraphStyle',
                '|',
                'insertLink',
                'insertTable',
                'formatOL',
                'formatUL',
                '|',
                'insertHR',
                'fullscreen',
                'html',
                '|',
                'undo',
                'redo',
            ],
        });
    }
    setupTomselect = (element) => {
        element.nextElementSibling?.remove();
        element.id = '';
        element.className = '';
        new TomSelect(element, { create: true }).clear();
        if (element.dataset.tomselectValidOptions && element.tomselect) {
            const validOptions = JSON.parse(element.dataset.tomselectValidOptions);
            for (const option in element.tomselect.options) {
                if (!validOptions.includes(option)) {
                    element.tomselect.removeOption(option);
                }
            }
        }
    };
}
