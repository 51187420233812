import AbstractController from './abstract/AbstractController';
export default class extends AbstractController {
    connect = () => {
        const cookie = this.getCookie('sessionLifeTimeCookie');
        if (cookie) {
            let sessionLifeTime = parseInt(cookie);
            //logout at least 1 min earlier
            sessionLifeTime -= 60;
            //convert seconds to milliseconds
            sessionLifeTime *= 1000;
            setInterval(() => {
                //check if sbb overlay blocker exists
                const blockerIsActiv = document.getElementsByClassName('sbb-overlay-blocker').length > 0;
                // if not exists then click save button
                if (!blockerIsActiv) {
                    document.getElementById('submit-on-logout-button')?.click();
                }
            }, sessionLifeTime);
        }
    };
    getCookie = (name) => {
        const cookieArray = document.cookie.split('; ');
        const cookieObject = cookieArray.reduce((acc, currentCookie) => {
            const [key, value] = currentCookie.split('=');
            acc[key] = value;
            return acc;
        }, {});
        return cookieObject[name] ? decodeURIComponent(cookieObject[name]) : null;
    };
}
