import AbstractController from './abstract/AbstractController';
import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';
import sbbDatepickerPlugin from '../plugins/flatpickr/sbb-datepicker';
import { DateTime } from 'luxon';
export default class default_1 extends AbstractController {
    static targets = ['input'];
    connect() {
        const defaultDate = this.inputTarget.value || this.inputTarget.dataset.defaultDate?.split(',');
        const enableTime = JSON.parse(this.inputTarget.dataset.enableTime || 'true');
        const mode = (this.inputTarget.dataset.mode || 'single');
        const minDate = this.inputTarget.dataset.minDate
            ? DateTime.fromISO(this.inputTarget.dataset.minDate).toJSDate()
            : undefined;
        const maxDate = this.inputTarget.dataset.maxDate
            ? DateTime.fromISO(this.inputTarget.dataset.maxDate).toJSDate()
            : undefined;
        this.datepicker = flatpickr(this.inputTarget, {
            enableTime,
            time_24hr: true,
            locale: German,
            altInput: true,
            altFormat: enableTime ? 'd.m.Y H:i' : 'd.m.Y',
            dateFormat: 'Z',
            mode,
            minDate,
            maxDate,
            defaultDate,
            disableMobile: true,
            static: false,
            allowInvalidPreload: true,
            minuteIncrement: 1,
            plugins: [sbbDatepickerPlugin()],
        });
        this.disableSubmitOnYearChange();
    }
    disableSubmitOnYearChange = () => {
        const nextYearButtons = Array.from(document.getElementsByClassName('next-year'));
        const lastYearButtons = Array.from(document.getElementsByClassName('last-year'));
        nextYearButtons.forEach(el => {
            el.setAttribute('type', 'button');
        });
        lastYearButtons.forEach(el => {
            el.setAttribute('type', 'button');
        });
    };
    toggle = () => {
        this.datepicker.altInput?.click();
    };
}
