import { hideErrors, showError } from '../helpers/form-helper';
import ModalController from './abstract/ModalController';
import translate from '../helpers/translation-helper';
export default class default_1 extends ModalController {
    static targets = ['inputName', 'inputDueDate', 'inputType', 'form', 'list', 'title'];
    connect = () => {
        this.element.addEventListener('show.bs.modal', () => {
            document.addEventListener('keydown', this.handleEnter);
        });
        this.element.addEventListener('hidden.modal.bs', () => {
            document.removeEventListener('keydown', this.handleEnter);
        });
        this.openOnError();
        this.uniqueTaskId = Math.max(...this.formTargets.map(form => Number.parseInt(form.dataset.taskId || '-1'))) + 1;
        this.element.addEventListener('hide.bs.modal', this.removeUnsubmittedForms);
        this.updateSelectLists();
    };
    handleEnter = (e) => {
        if (e.key === 'Enter' && this.element.classList.contains('show')) {
            e.preventDefault();
            this.clickSubmitButton();
        }
    };
    openOnError = () => {
        if (this.element.dataset.validationErrors === '1') {
            const errorForm = this.formTargets.find(form => form.firstElementChild?.classList.contains('-has-error'));
            if (!errorForm) {
                return;
            }
            this.activeForm = errorForm;
            for (const form of this.formTargets) {
                form.classList.toggle('d-none', form !== this.activeForm);
            }
            this.modal.show();
        }
    };
    open = async (callback, title, types, note, taskId) => {
        const saveButton = this.element.querySelector('.modal-footer .sbb-button.primary');
        this.titleTarget.innerText = title;
        if (!taskId) {
            saveButton.innerText = await translate('label.add', 'base');
            if (this.formTargets.length === 0) {
                this.listTarget.append(this.activeForm);
            }
            if (this.formTargets[0].dataset.taskId === '') {
                this.activeForm = this.formTargets[0];
                this.inputTypeTargets[0].tomselect?.clear();
            }
            else {
                const checkedRadiobutton = this.formTargets[0].querySelector('input:checked');
                const node = this.formTargets[0].cloneNode(true);
                this.formTargets[0].parentElement?.append(node);
                //FIXME: Workaround for cloned radiobutton overriding original form
                if (checkedRadiobutton) {
                    checkedRadiobutton.checked = true;
                }
                this.activeForm = this.formTargets[this.formTargets.length - 1];
                this.activeForm.dataset.taskId = '';
                this.updateSelectLists();
                this.updateFormIndex(this.activeForm);
                this.resetForm(this.activeForm);
            }
        }
        else {
            saveButton.innerText = await translate('label.save', 'base');
            const editForm = this.formTargets.find(form => form.dataset.taskId === taskId);
            if (!editForm) {
                return;
            }
            this.activeForm = editForm;
        }
        this.toggleAllowedTypes(types);
        for (const form of this.formTargets) {
            form.classList.toggle('d-none', form !== this.activeForm);
        }
        this.activeForm.dataset.note = note;
        this.callback = callback;
        this.modal.show();
    };
    updateSelectLists = () => {
        this.inputAssignedUserList = Array.from(this.element.querySelectorAll('select[name*=assignedUser]'));
    };
    toggleAllowedTypes = (allowedTypes) => {
        const index = this.formTargets.indexOf(this.activeForm);
        const input = this.inputTypeTargets[index];
        allowedTypes = allowedTypes.map(type => type.toLowerCase());
        allowedTypes = allowedTypes.filter(type => type !== 'video');
        if (!input.tomselect) {
            input.addEventListener('tomselect-ready', () => {
                this.updateTomOptions(input.tomselect, allowedTypes);
            });
        }
        else {
            this.updateTomOptions(input.tomselect, allowedTypes);
        }
    };
    updateTomOptions = (tomselect, allowedTypes) => {
        for (const type of Array.from(Object.keys(tomselect.options))) {
            tomselect.updateOption(type, {
                ...tomselect.options[type],
                disabled: !allowedTypes.includes(type),
            });
        }
    };
    clickSubmitButton = async () => {
        const valid = await this.handleValidation();
        let taskId = this.activeForm.dataset.taskId;
        const note = this.activeForm.dataset.note;
        if (!valid) {
            return;
        }
        const index = this.formTargets.indexOf(this.activeForm);
        if (taskId === '') {
            taskId = this.uniqueTaskId.toString();
            this.activeForm.dataset.taskId = taskId;
        }
        const userSelect = this.inputAssignedUserList[index].tomselect;
        const typeSelect = this.inputTypeTargets[index].tomselect;
        const task = {
            ghostId: taskId,
            assignedUser: userSelect?.options[userSelect?.items[0]].text || '',
            dueDate: this.inputDueDateTargets[index].value,
            type: typeSelect?.items[0] || '',
            note: note,
            name: this.inputNameTargets[index].value,
        };
        this.callback?.(task);
        this.uniqueTaskId += 1;
        this.modal.hide();
    };
    handleValidation = async () => {
        const index = this.formTargets.indexOf(this.activeForm);
        const isNewForm = this.activeForm.dataset.taskId === '';
        hideErrors(this.activeForm);
        let hasErrors = false;
        if (isNewForm) {
            if (this.inputNameTargets[index].value.trim() === '') {
                hasErrors = true;
                showError(this.inputNameTargets[index], await translate('workflow.validation.required', 'admin'));
            }
            if (!this.inputTypeTargets[index].tomselect?.getValue()) {
                hasErrors = true;
                showError(this.inputTypeTargets[index], await translate('workflow.validation.required', 'admin'));
            }
        }
        if (!this.inputDueDateTargets[index].value) {
            hasErrors = true;
            showError(this.inputDueDateTargets[index], await translate('workflow.validation.required', 'admin'));
        }
        if (!this.inputAssignedUserList[index].tomselect?.getValue()) {
            hasErrors = true;
            showError(this.inputAssignedUserList[index], await translate('workflow.validation.required', 'admin'));
        }
        if (this.inputDueDateTargets[index]._flatpickr.selectedDates.length == 1 &&
            new Date(Date.now() + 2 * 60 * 60 * 1000) >= this.inputDueDateTargets[index]._flatpickr.selectedDates[0]) {
            showError(this.inputDueDateTargets[index], await translate('workflow.validation.date_2h_in_future', 'admin'));
            hasErrors = true;
        }
        return !hasErrors;
    };
    resetForm = (form) => {
        const index = this.formTargets.indexOf(form);
        this.inputNameTargets[index].value = '';
        this.resetTomSelect(this.inputTypeTargets[index]);
        this.resetFlatpickr(this.inputDueDateTargets[index]);
        this.resetTomSelect(this.inputAssignedUserList[index]);
    };
    resetTomSelect = (element) => {
        element.nextElementSibling?.remove();
        element.id = '';
        element.className = '';
        element.value = '';
        element.addEventListener('tomselect-ready', () => {
            element.tomselect.clear();
        });
    };
    resetFlatpickr = (element) => {
        element.nextElementSibling?.remove();
        element.value = '';
        element._flatpickr?.clear();
    };
    removeForm = (id) => {
        if (this.formTargets.length === 1) {
            this.activeForm = this.formTargets[0];
            this.resetForm(this.activeForm);
            this.activeForm.dataset.taskId = '';
            this.formTargets[0].remove();
            return;
        }
        this.formTargets.find(form => form.dataset.taskId === id)?.remove();
        this.updateSelectLists();
    };
    removeUnsubmittedForms = () => {
        for (const form of this.formTargets.filter(f => f.dataset.taskId === '')) {
            form.remove();
        }
    };
    updateFormIndex = (form) => {
        const index = this.formTargets.indexOf(form);
        const attributesToUpdate = [
            'id',
            'name',
            'aria-controls',
            'aria-labelledby',
            'data-bs-target',
            'data-model',
            'for',
        ];
        for (const attribute of attributesToUpdate) {
            const elements = form.querySelectorAll(`[${attribute}*="0"]`);
            for (const element of elements) {
                element.setAttribute(attribute, element.getAttribute(attribute).replace('0', index.toString()));
            }
        }
    };
}
