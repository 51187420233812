import AbstractController from './abstract/AbstractController';
import { getNotificationToast } from '../helpers/notification-helper';
export default class default_1 extends AbstractController {
    static targets = ['requestButton'];
    async requestAccessibilityCheck() {
        const workflowId = this.requestButtonTarget.dataset.workflowIdValue;
        if (!workflowId) {
            return;
        }
        this.requestButtonTarget.disabled = true;
        const response = await fetch(`/workflow/news-article/accessibility-check/${workflowId}`, {
            method: 'POST',
        });
        const result = await response.json();
        const notificationId = 'request_accessibility_check_' + (result.success ? 'success' : 'error');
        const notificationToastElement = getNotificationToast(notificationId);
        notificationToastElement?.show();
    }
}
