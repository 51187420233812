import AbstractController from './abstract/AbstractController';
import { initTooltips } from '../helpers/tooltip-helper';
import translate from '../helpers/translation-helper';
export default class default_1 extends AbstractController {
    static targets = ['list', 'task', 'header', 'startAllWorkflowsButton'];
    static outlets = ['task-modal'];
    static values = {
        category: String,
    };
    connect = () => {
        initTooltips(this.element);
    };
    removeTask = (e) => {
        e.currentTarget.closest('[data-task-list-target=task]')?.remove();
        this.taskModalOutlet.removeForm(e.currentTarget.dataset.taskId || '');
        if (this.taskTargets.length === 0) {
            this.headerTarget.classList.add('d-none');
        }
        this.startAllWorkflowsButtonTarget.classList.add('d-none');
    };
    openTaskModal = async (e) => {
        const { taskId, note } = e.currentTarget.dataset;
        const allowedWorkflowTypes = JSON.parse(this.element.dataset.types || '[]');
        this.taskModalOutlet.open(this.handleTaskModalResponse, await translate(`thema_bucket.${taskId ? 'edit' : 'add'}_${this.categoryValue}`, 'workflow'), allowedWorkflowTypes, note || '', taskId);
    };
    handleTaskModalResponse = async (task) => {
        const taskTarget = this.taskTargets.find(taskTarget => taskTarget.dataset.taskId === task.ghostId);
        const taskRow = await this.fetchTaskRow(task);
        if (!taskTarget) {
            this.listTarget.innerHTML += taskRow;
            this.headerTarget.classList.remove('d-none');
        }
        else {
            taskTarget.outerHTML = taskRow;
        }
        initTooltips(this.element);
        this.startAllWorkflowsButtonTarget.classList.add('disabled');
        this.taskTargets
            .find(taskTarget => taskTarget.dataset.taskId === task.ghostId)
            ?.querySelector('[data-start-workflow-button]')
            ?.classList.add('disabled');
        this.startAllWorkflowsButtonTarget.classList.add('d-none');
    };
    fetchTaskRow = async (task) => {
        const params = new URLSearchParams();
        params.append('task', JSON.stringify(task));
        const url = this.listTarget.dataset.templatePath;
        const response = await fetch(`${url}?${params.toString()}`);
        const parsedResponse = await response.text();
        return parsedResponse;
    };
}
