import AbstractController from './abstract/AbstractController';
import { checkForNotifications, getNotificationToast } from '../helpers/notification-helper';
import { Collapse } from 'bootstrap';
import { submitFormAsRequest } from '../helpers/form-helper';
export default class extends AbstractController {
    connect = () => {
        this.setSourceLanguageReadonly();
        this.showErrors();
        this.expandSummaryAccordion();
        checkForNotifications();
    };
    showErrors = () => {
        if (this.element.dataset.valid) {
            return;
        }
        const elementsWithErrors = this.element.querySelectorAll('.-has-error');
        if (elementsWithErrors.length === 0) {
            return;
        }
        const elem = elementsWithErrors[0];
        if (elem === null || elem.offsetTop === undefined) {
            return;
        }
        const scrollMargin = parseInt(window.getComputedStyle(elem).scrollMarginTop.replace('px', ''));
        window.scrollTo(0, elem.offsetTop - scrollMargin);
    };
    expandSummaryAccordion = () => {
        if (this.element.querySelector('form[name=empty]') !== null) {
            new Collapse('#collapse-summary').toggle();
        }
    };
    handleNewsArticleSelection = (event) => {
        const customEvent = new CustomEvent('news-article-workflow-set', {
            detail: {
                value: event.target.value,
            },
        });
        window.dispatchEvent(customEvent);
    };
    handleSourceLanguageChange = (event) => {
        const input = event.target;
        let languageSelection = document.querySelectorAll('[name$="[languages][]"]');
        if (languageSelection.length == 0) {
            languageSelection = document.querySelectorAll('[name$="[targetLanguages][]"]');
        }
        for (const checkBox of languageSelection) {
            if (checkBox.value === input.value) {
                checkBox.checked = true;
                checkBox.readOnly = true;
            }
            else {
                checkBox.readOnly = false;
            }
        }
    };
    setSourceLanguageReadonly = () => {
        const sourceLanguage = this.element.querySelector("[name$='[sourceLanguage]'][checked]")?.value;
        if (!sourceLanguage) {
            return;
        }
        const targetLanguageInput = document.querySelector(`[name$="[languages][]"][value=${sourceLanguage}]`) ??
            document.querySelector(`[name$="[targetLanguages][]"][value=${sourceLanguage}]`);
        if (targetLanguageInput) {
            targetLanguageInput.readOnly = true;
        }
    };
    changeMinOneNumber = (event) => {
        const inputElement = event.target;
        const errorWrapper = inputElement.parentElement?.querySelector('.error-wrapper');
        if (!errorWrapper) {
            return;
        }
        if (inputElement.validity.valid) {
            errorWrapper.innerHTML = '';
            return;
        }
        errorWrapper.innerHTML = inputElement.validationMessage;
    };
    fetchPdf = async (event) => {
        const inputElement = event.target;
        const form = inputElement.form;
        if (form === null) {
            return;
        }
        inputElement.classList.add('pe-none');
        inputElement.classList.add('disabled');
        const response = await submitFormAsRequest(form);
        if (response.status !== 200) {
            getNotificationToast('error-export')?.show();
        }
        inputElement.classList.remove('pe-none');
        inputElement.classList.remove('disabled');
    };
}
