import AbstractController from './abstract/AbstractController';
import translate from '../helpers/translation-helper';
import { hideError, showError } from '../helpers/form-helper';
export default class default_1 extends AbstractController {
    static targets = [
        'component',
        'list',
        'fromTime',
        'fromTimeValue',
        'fromTimeInput',
        'toTime',
        'toTimeValue',
        'toTimeInput',
        'content',
        'contentValue',
        'contentInput',
        'created_by',
        'created_by_not_saved',
        'editButton',
        'saveButton',
        'cancelButton',
        'deleteButton',
        'scheduledForDeletion',
        'cuePointIdInput',
        'createdByInput',
        'scheduledForSave',
        'updatedAt',
    ];
    static outlets = ['videoComment'];
    connect = () => {
        this.template = this.componentTargets[0].cloneNode(true);
    };
    addComponent = async () => {
        const clonedComponent = this.template.cloneNode(true);
        const clonedElement = this.listTarget.appendChild(clonedComponent);
        const index = this.componentTargets.indexOf(clonedElement);
        //display correct fields
        this.saveButtonTargets[index].classList.remove('d-none');
        this.cancelButtonTargets[index].classList.remove('d-none');
        this.editButtonTargets[index].classList.add('d-none');
        this.deleteButtonTargets[index].classList.add('d-none');
        this.fromTimeTargets[index].classList.add('d-none');
        this.toTimeTargets[index].classList.add('d-none');
        this.contentTargets[index].classList.add('d-none');
        this.fromTimeInputTargets[index].classList.remove('d-none');
        this.toTimeInputTargets[index].classList.remove('d-none');
        this.contentInputTargets[index].classList.remove('d-none');
        //clean data
        this.fromTimeInputTargets[index].value = '';
        this.toTimeInputTargets[index].value = '';
        this.contentInputTargets[index].value = '';
        this.fromTimeValueTargets[index].value = '';
        this.toTimeValueTargets[index].value = '';
        this.contentValueTargets[index].value = '';
        this.cuePointIdInputTargets[index].value = '';
        this.createdByInputTargets[index].value = '';
        this.created_byTargets[index].innerText = '';
        this.updatedAtTargets[index].innerText = await translate('video.displayed_after_save', 'workflow');
        this.contentTargets[index].innerText = '';
        this.created_by_not_savedTargets[index].innerText = await translate('video.displayed_after_save', 'workflow');
        this.scheduledForDeletionTargets[index].checked = false;
        this.updateComponentIndex(clonedElement);
    };
    delete = async (event) => {
        const index = this.deleteButtonTargets.indexOf(event.currentTarget);
        if (confirm(await translate('video.sure', 'workflow'))) {
            this.componentTargets[index].classList.add('d-none');
            //set an attribute, scheduled for deletion
            this.scheduledForDeletionTargets[index].checked = true;
        }
    };
    edit = (event) => {
        const index = this.editButtonTargets.indexOf(event.currentTarget);
        this.fromTimeInputTargets[index].value = this.fromTimeValueTargets[index].value;
        this.toTimeInputTargets[index].value = this.toTimeValueTargets[index].value;
        this.contentInputTargets[index].value = this.contentValueTargets[index].value;
        this.saveButtonTargets[index].classList.remove('d-none');
        this.cancelButtonTargets[index].classList.remove('d-none');
        this.editButtonTargets[index].classList.add('d-none');
        this.deleteButtonTargets[index].classList.add('d-none');
        this.fromTimeTargets[index].classList.add('d-none');
        this.toTimeTargets[index].classList.add('d-none');
        this.contentTargets[index].classList.add('d-none');
        this.fromTimeInputTargets[index].classList.remove('d-none');
        this.toTimeInputTargets[index].classList.remove('d-none');
        this.toTimeInputTargets[index].classList.remove('d-none');
        this.contentInputTargets[index].classList.remove('d-none');
    };
    save = async (event) => {
        const index = this.saveButtonTargets.indexOf(event.currentTarget);
        hideError(this.fromTimeInputTargets[index]);
        hideError(this.toTimeInputTargets[index]);
        hideError(this.contentInputTargets[index]);
        let hasErrors = false;
        if (this.fromTimeInputTargets[index].value.length != 8) {
            hasErrors = true;
            showError(this.fromTimeInputTargets[index], await translate('workflow.validation.required', 'admin'));
        }
        if (this.toTimeInputTargets[index].value.length != 8) {
            hasErrors = true;
            showError(this.toTimeInputTargets[index], await translate('workflow.validation.required', 'admin'));
        }
        const toSeconds = this.convertDateToSeconds(this.convertStringToDate(this.fromTimeInputTargets[index].value));
        if (toSeconds > Number(this.element.dataset.duration) - 2) {
            showError(this.toTimeInputTargets[index], await translate('video.to_long', 'workflow'));
            hasErrors = true;
        }
        if (this.contentInputTargets[index].value.length == 0) {
            hasErrors = true;
            showError(this.contentInputTargets[index], await translate('workflow.validation.required', 'admin'));
        }
        if (hasErrors) {
            return;
        }
        this.saveButtonTargets[index].classList.add('d-none');
        this.cancelButtonTargets[index].classList.add('d-none');
        this.editButtonTargets[index].classList.remove('d-none');
        this.deleteButtonTargets[index].classList.remove('d-none');
        this.fromTimeInputTargets[index].classList.add('d-none');
        this.toTimeInputTargets[index].classList.add('d-none');
        this.contentInputTargets[index].classList.add('d-none');
        this.fromTimeTargets[index].classList.remove('d-none');
        this.toTimeTargets[index].classList.remove('d-none');
        this.contentTargets[index].classList.remove('d-none');
        this.fromTimeTargets[index].innerText = this.fromTimeInputTargets[index].value;
        this.toTimeTargets[index].innerText = this.toTimeInputTargets[index].value;
        this.contentTargets[index].innerText = this.contentInputTargets[index].value;
        this.fromTimeValueTargets[index].value = this.fromTimeInputTargets[index].value;
        this.toTimeValueTargets[index].value = this.toTimeInputTargets[index].value;
        this.contentValueTargets[index].value = this.contentInputTargets[index].value;
        this.scheduledForSaveTargets[index].checked = true;
    };
    cancel = (event) => {
        const index = this.cancelButtonTargets.indexOf(event.currentTarget);
        //check if createdBy is already set, this means this comment has existed before
        if (this.created_byTargets[index].innerText == '') {
            //delete componenent
            this.componentTargets[index].remove();
            return;
        }
        this.saveButtonTargets[index].classList.add('d-none');
        this.cancelButtonTargets[index].classList.add('d-none');
        this.editButtonTargets[index].classList.remove('d-none');
        this.deleteButtonTargets[index].classList.remove('d-none');
        this.fromTimeInputTargets[index].classList.add('d-none');
        this.toTimeInputTargets[index].classList.add('d-none');
        this.contentInputTargets[index].classList.add('d-none');
        this.fromTimeTargets[index].classList.remove('d-none');
        this.toTimeTargets[index].classList.remove('d-none');
        this.contentTargets[index].classList.remove('d-none');
        this.fromTimeInputTargets[index].value = this.fromTimeTargets[index].innerText;
        this.toTimeInputTargets[index].value = this.toTimeTargets[index].innerText;
        this.contentInputTargets[index].value = this.contentTargets[index].innerText;
    };
    updateComponentIndex = (component) => {
        const index = this.componentTargets.length - 1;
        if (index < 0) {
            return;
        }
        const attributesToUpdate = [
            'id',
            'name',
            'aria-controls',
            'for',
            'aria-labelledby',
            'data-bs-target',
            'data-model',
        ];
        for (const attribute of attributesToUpdate) {
            const elements = component.querySelectorAll(`[${attribute}]`);
            for (const element of elements) {
                element.setAttribute(attribute, element.getAttribute(attribute).replace(/\d+/, index.toString()));
            }
        }
    };
    convertDateToSeconds = (time) => {
        return time.getHours() * 3600 + time.getMinutes() * 60 + time.getSeconds();
    };
    convertStringToDate = (time) => {
        const parts = time.split(':').map(part => parseInt(part, 10));
        // Create a new Date object
        const date = new Date(Date.UTC(70, 0));
        date.setHours(parts[0]);
        date.setMinutes(parts[1]);
        date.setSeconds(parts[2]);
        return date;
    };
    updateToTime = async (event) => {
        const index = this.fromTimeInputTargets.indexOf(event.currentTarget);
        hideError(this.toTimeInputTargets[index]);
        const date = this.convertStringToDate(this.fromTimeInputTargets[index].value);
        //add two seconds for the toDate, default 2 seconds later than fromDate
        date.setSeconds(date.getSeconds() + 2);
        const toSeconds = this.convertDateToSeconds(date);
        if (toSeconds > Number(this.element.dataset.duration)) {
            showError(this.toTimeInputTargets[index], await translate('video.to_long', 'workflow'));
        }
        // Format the updated time back into a string
        this.toTimeInputTargets[index].value = [
            date.getHours().toString().padStart(2, '0'),
            date.getMinutes().toString().padStart(2, '0'),
            date.getSeconds().toString().padStart(2, '0'),
        ].join(':');
    };
}
