import AbstractController from './abstract/AbstractController';
import { create } from '@frontify/frontify-finder';
import { Modal, Toast } from 'bootstrap';
import { checkAssetAccess } from '../helpers/frontify-helper';
export default class default_1 extends AbstractController {
    static targets = ['imageWrapper', 'finderButton', 'preview', 'input'];
    backgroundImageInput = undefined;
    connect = () => {
        this.backgroundImageInput = document.querySelector('[name*="[backgroundImageUrl]"]');
    };
    openFinder = async () => {
        const modalElement = document.getElementById('frontifyModal');
        this.modal = Modal.getOrCreateInstance('#frontifyModal');
        this.modal.hide();
        if (this.modal === null || modalElement === null) {
            return;
        }
        if (modalElement.querySelector('iframe') !== null) {
            this.modal.show();
            return;
        }
        const finder = await create({
            clientId: 'client-z9mkcbf9tr6c6qff',
            domain: 'brand.sbb.ch',
            options: {
                allowMultiSelect: false,
            },
        }).catch(() => {
            return null;
        });
        if (!finder) {
            return;
        }
        this.modal.show();
        finder.onAssetsChosen(assets => {
            const path = this.element.dataset.checkAccessLink;
            if (path === '' || path === undefined) {
                return false;
            }
            if (!checkAssetAccess(assets, path)) {
                const warningToast = new Toast('#frontifyModal .toast.-warning');
                warningToast.show();
                return;
            }
            const firstAsset = assets[0];
            if (!firstAsset || !firstAsset.previewUrl || !this.backgroundImageInput) {
                return;
            }
            this.backgroundImageInput.classList.remove('visually-hidden');
            const previewURL = new URL(firstAsset.previewUrl);
            previewURL.searchParams.append('width', '400');
            previewURL.searchParams.append('format', 'webp');
            this.backgroundImageInput.value = previewURL.toString();
            this.previewTarget.src = previewURL.toString();
            this.imageWrapperTarget.classList.remove('d-none');
            this.finderButtonTarget.classList.add('d-none');
            this.modal?.hide();
        });
        finder.onCancel(() => {
            this.modal?.hide();
        });
        const finderWrapper = document.querySelector('.frontify-finder-wrapper');
        if (finderWrapper === null) {
            return;
        }
        finder.mount(finderWrapper);
    };
    removeImage = () => {
        this.finderButtonTarget.classList.remove('d-none');
        this.imageWrapperTarget.classList.add('d-none');
        this.previewTarget.src = '';
    };
}
