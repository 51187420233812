import { Collapse } from 'bootstrap';
import AbstractController from './abstract/AbstractController';
import { getNotificationToast } from '../helpers/notification-helper';
import translate from '../helpers/translation-helper';
import { initTooltips } from '../helpers/tooltip-helper';
export default class default_1 extends AbstractController {
    static targets = ['collapse', 'assignedUser'];
    static outlets = ['delete-modal'];
    connect = () => {
        this.initCollapse();
        initTooltips(this.element);
        this.collapse.show();
    };
    initCollapse = () => {
        this.collapse = new Collapse(this.collapseTarget, { toggle: false });
        this.collapseTarget.addEventListener('show.bs.collapse', e => {
            if (!(e.target === this.collapseTarget)) {
                return;
            }
            this.element.classList.add('-open');
            this.updateFooter(true);
        });
        this.collapseTarget.addEventListener('hide.bs.collapse', e => {
            if (!(e.target === this.collapseTarget)) {
                return;
            }
            this.element.classList.remove('-open');
            this.updateFooter(false);
        });
    };
    open = () => {
        this.collapse.show();
    };
    close = () => {
        this.collapse.hide();
    };
    validateForm = () => {
        document.querySelector('[data-control="save-and-validate"]')?.click();
    };
    updateFooter = (open) => {
        const footer = document.getElementsByTagName('footer')[0];
        footer.classList.toggle('-sidebar-open', open);
    };
    setAssignedUser = (name) => {
        this.assignedUserTarget.textContent = name;
    };
    clickDeleteWorkflowButton = async (e) => {
        const button = e.currentTarget;
        const { path, token } = button.dataset;
        const title = await translate('delete.delete_modal_title', 'workflow');
        const description = await translate('delete.delete_modal_text', 'workflow');
        if (!(this.deleteModalOutlet && path && token && title && description)) {
            return;
        }
        this.deleteModalOutlet.open({ title, description }, { path, token, apiRequest: false });
    };
    clickBack = async (e) => {
        const button = e.currentTarget;
        const { path } = button.dataset;
        if (!path) {
            return;
        }
        const response = await fetch(path, {
            method: 'POST',
        });
        if (!(response.status === 200)) {
            getNotificationToast('error-back')?.show();
            return;
        }
        window.location.reload();
    };
}
