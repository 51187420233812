import { Toast } from 'bootstrap';
import TomSelect from 'tom-select';
import IconTrash from '/public/icons/trash-white.svg';
import ModalController from './abstract/ModalController';
import translate from '../helpers/translation-helper';
export default class default_1 extends ModalController {
    static targets = ['scroll', 'dropzone', 'expiresOnDatepicker', 'submitButton'];
    connect = () => {
        //remove current-scene-index, in case that we are not on the storyboard page
        localStorage.removeItem('current-scene-index');
        this.initTagSelect();
        this.initDropzone();
        this.submitButtonTarget.addEventListener('click', this.uploadAsset);
    };
    initTagSelect = () => {
        this.tagSelect = new TomSelect('#dam_upload_asset_tags', {
            create: true,
            plugins: {
                remove_button: {
                    title: 'Remove this item',
                },
            },
        });
    };
    initDropzone = () => {
        const { placeholderText, placeholderButtonText, clearButtonText } = this.dropzoneTarget.dataset;
        const placeholderElement = this.dropzoneTarget.nextElementSibling;
        if (!(placeholderElement && placeholderText && placeholderButtonText && clearButtonText)) {
            return;
        }
        const placeholderButton = `<button class="dropzone-placeholder-button sbb-button slim">${placeholderButtonText}</button>`;
        placeholderElement.innerHTML = `<div class="d-flex flex-column"><span class="mb-3">${placeholderText}</span>${placeholderButton}</div>`;
        this.clearPreviewButton = this.dropzoneTarget.parentElement?.querySelector('.dropzone-preview-button');
        if (!this.clearPreviewButton) {
            return;
        }
        const buttonTemplate = `<span>${clearButtonText}</span>${IconTrash}`;
        this.clearPreviewButton.classList.add('sbb-button', 'slim');
        this.clearPreviewButton.innerHTML = buttonTemplate;
    };
    updateExpiresOn = (event) => {
        this.expiresOnDatepickerTarget._flatpickr.clear();
        this.expiresOnDatepickerTarget._flatpickr._input.disabled = event.target.checked;
    };
    uploadAsset = async () => {
        // check of attachment is of type image
        this.submitButtonTarget.disabled = true;
        this.resetErrors();
        const path = this.element.dataset.path;
        const uploadFormElement = this.element.querySelector('form');
        if (!uploadFormElement) {
            return;
        }
        const formdata = new FormData(uploadFormElement);
        const asset = formdata.get('dam_upload_asset[asset]');
        if (asset instanceof File) {
            if (!asset.type.match('image.*')) {
                this.handleErrors({
                    asset: await translate('workflow.validation.required', 'admin'),
                });
            }
        }
        if (!(path && formdata)) {
            return;
        }
        const response = await fetch(path, {
            method: 'POST',
            body: formdata,
        });
        if (!(response.status === 400 || response.status === 200)) {
            const errorToast = new Toast('#uploadAssetModal .toast.-error');
            errorToast.show();
            this.scrollTarget.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            this.submitButtonTarget.disabled = false;
            return;
        }
        const jsonResponse = await response.json();
        if (response.status === 400) {
            this.handleErrors(jsonResponse.errors);
            this.submitButtonTarget.disabled = false;
        }
        if (response.status === 200) {
            if (localStorage.getItem('current-scene-index')) {
                this.handleSuccessForVideoWorkflow(jsonResponse);
            }
            else {
                this.handleSuccess(jsonResponse);
            }
            uploadFormElement.reset();
            this.clearPreviewButton?.click();
            this.tagSelect?.clear();
            this.submitButtonTarget.disabled = false;
        }
    };
    handleErrors = (errors) => {
        const warningToast = new Toast('#uploadAssetModal .toast.-warning');
        warningToast.show();
        for (const field in errors) {
            const errorContainer = this.element
                .querySelector(`[name='dam_upload_asset[${field}]'`)
                ?.closest('.sbb-form-field')
                ?.querySelector('.error-wrapper');
            if (!errorContainer) {
                return;
            }
            errorContainer.innerHTML = errors[field];
        }
        this.scrollTarget.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    resetErrors = () => {
        for (const errorContainer of this.element.getElementsByClassName('error-wrapper')) {
            errorContainer.innerHTML = '';
        }
    };
    handleSuccessForVideoWorkflow(img) {
        const currentSceneIndex = localStorage.getItem('current-scene-index');
        const wrapper = document.querySelector(`[data-scene-index="${currentSceneIndex}"]`);
        if (!wrapper) {
            return;
        }
        const assetWebp = wrapper.querySelector('[data-frontify-finder-target="assetWebp"]');
        const assetJpg = wrapper.querySelector('[data-frontify-finder-target="assetJpg"]');
        const assetName = wrapper.querySelector('[data-frontify-finder-target="fileNameWrapper"]');
        const assetWrapper = wrapper.querySelector('[data-frontify-finder-target="assetWrapper"]');
        const imageWrapper = wrapper.querySelector('[data-frontify-finder-target="imageWrapper"]');
        const finderButton = wrapper.querySelector('[data-frontify-finder-target="finderButton"]');
        const imageWorkflowButton = wrapper.querySelector('[data-frontify-finder-target="imageWorkflowButton"]');
        if (!(assetName && assetJpg && assetWebp && assetWrapper && imageWrapper && imageWorkflowButton && finderButton)) {
            return;
        }
        assetName.innerText = img.name;
        assetJpg.src = img.src;
        assetWebp.srcset = img.src_webp;
        const headerImageValueField = wrapper.querySelector(`[data-header-image-field]`);
        if (headerImageValueField) {
            headerImageValueField.value = img.id;
        }
        assetWrapper.classList.remove('d-none');
        imageWrapper.classList.remove('d-none');
        finderButton.classList.add('d-none');
        imageWorkflowButton.classList.add('d-none');
        this.successNotification?.show();
        this.modal.hide();
    }
    handleSuccess = (img) => {
        const imageContainer = document.querySelector('[data-frontify-finder-image-target="imageWrapper"]');
        if (!imageContainer) {
            return;
        }
        const imagesInput = document.querySelector("[name='production[images]']");
        if (!imagesInput) {
            return;
        }
        const imageIds = JSON.parse(imagesInput.value || '[]');
        imageIds.push(img.id);
        imagesInput.value = JSON.stringify(imageIds);
        const imageTemplate = document.getElementById('templateRow').content.cloneNode(true);
        const assetWebp = imageTemplate.querySelector('[data-frontify-finder-image-target="assetWebp"]');
        const assetJpg = imageTemplate.querySelector('[data-frontify-finder-image-target="assetJpg"]');
        const assetName = imageTemplate.querySelector('[data-frontify-finder-image-target="fileNameWrapper"]');
        const assetWrapper = imageTemplate.querySelector('[data-frontify-finder-image-target="assetWrapper"]');
        const imageRow = assetWrapper?.closest('.image-row');
        imageRow?.classList.add('-uploaded');
        const selectedImagePlaceholderField = imageRow?.querySelector('[data-selected-image-placeholer]');
        if (!(assetWebp && assetJpg && assetName && assetWrapper && selectedImagePlaceholderField)) {
            return;
        }
        selectedImagePlaceholderField.value = img.id;
        assetName.innerText = img.name;
        assetJpg.src = img.src;
        assetWebp.srcset = img.src_webp;
        assetWrapper.dataset.id = img.id;
        imageContainer.append(imageTemplate);
        this.successNotification?.show();
        this.modal.hide();
    };
}
