// @ts-ignore
import FroalaEditor from 'froala-editor';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/languages/de';
import AbstractController from './abstract/AbstractController';
// TODO This is only a poc
export default class extends AbstractController {
    metaData = [];
    connect = () => {
        this.editor = new FroalaEditor('#froala-redasys', {
            key: 'iTB2xC5C4C3B2C1G3E1pZGCTRSAPJWTLPLZHTQQb1JGZxE2F2G2F1B10B2A1E6C1A1==',
            language: 'de',
            toolbarSticky: true,
            toolbarStickyOffset: 64,
            attribution: false,
            listAdvancedTypes: false,
            htmlAllowedEmptyTags: ['span'],
            inlineClasses: {
                'fr-class-inline': 'inline',
                'fr-class-red-highlighted': 'Rot',
            },
            paragraphStyles: {
                'fr-class-red-highlighted': 'Rot',
            },
            linkList: [
                {
                    text: 'SBB',
                    href: 'https://sbb.ch',
                    target: '_blank',
                },
                {
                    text: 'URL news',
                    href: 'news://',
                    target: '_blank',
                },
                {
                    text: 'URL https',
                    href: 'https://',
                    target: '_blank',
                },
                {
                    text: 'URL http',
                    href: 'http://',
                    target: '_blank',
                },
                {
                    text: 'Mail',
                    href: 'mailto://',
                },
                {
                    text: 'Telefon',
                    href: 'tel://',
                },
            ],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            events: {
                'popups.show.link.insert': function () {
                    if (!this.popups.get('link.insert')[0].querySelector('[id^="fr-link-target"]')) {
                        return;
                    }
                    this.popups.get('link.insert')[0].querySelector('[id^="fr-link-target"]').checked = true;
                },
            },
            pastePlain: true,
            toolbarButtons: [
                'insertComment',
                'bold',
                'italic',
                'subscript',
                'superscript',
                'underline',
                'strikeThrough',
                'clearFormatting',
                'inlineClass',
                '|',
                'paragraphFormat',
                'paragraphStyle',
                '|',
                'insertLink',
                'insertTable',
                'formatOL',
                'formatUL',
                '|',
                'insertHR',
                'fullscreen',
                'html',
                '|',
                'undo',
                'redo',
            ],
        });
    };
    addComment = () => {
        const prompt = window.prompt('Ja?', '');
        if (prompt === null || prompt === '') {
            return;
        }
        else {
            if (this.editorField === null) {
                return;
            }
            const range = {
                index: 0,
                length: window.getSelection()?.getRangeAt(0).toString().length ?? 0,
            };
            if (range) {
                this.metaData.push({ range: range, comment: prompt });
                this.drawComments();
            }
            else {
                alert('User cursor is not in editor');
            }
        }
    };
    drawComments = () => {
        const commentContainer = document.querySelector('[data-control="word20Comments"]');
        if (commentContainer === null) {
            return;
        }
        const templateRow = document.querySelector('#word20CommentLink');
        if (templateRow === null) {
            return;
        }
        for (const [index, data] of this.metaData.entries()) {
            const listElement = templateRow.content.cloneNode(true);
            const liElement = listElement.querySelector('li');
            const aElement = listElement.querySelector('a');
            if (liElement === null || aElement === null) {
                return;
            }
            liElement.innerText = data.comment;
            aElement.dataset.index = index.toString();
            commentContainer.append(listElement);
        }
    };
    answerComment = (event) => {
        const replayTo = event.target.closest('[data-comment-wrapper]');
        if (replayTo === null) {
            return;
        }
        this.addAnswer(replayTo);
    };
    addAnswer = (container) => {
        const templateAnswerRow = document.querySelector('#word20CommentAnswer');
        if (templateAnswerRow === null) {
            return;
        }
        const listElement = templateAnswerRow.content.cloneNode(true);
        const liElement = listElement.querySelector('li');
        if (liElement === null) {
            return;
        }
        const prompt = window.prompt('Antworten?', '');
        if (prompt === null || prompt === '') {
            return;
        }
        else {
            liElement.innerText = prompt;
            container.append(listElement);
        }
    };
}
