import ModalController from './abstract/ModalController';
import translate from '../helpers/translation-helper';
import { Collapse } from 'bootstrap';
export default class default_1 extends ModalController {
    static targets = ['counter', 'textblock', 'pasteButton', 'customTextButton'];
    static outlets = ['collection'];
    connect = () => {
        this.selectedTextblockIds = [];
        this.element.addEventListener('hide.bs.modal', this.hideModalCleanup);
    };
    open = (componentId, input, showCustomTextButton) => {
        if (this.textblockTargets.length == 0) {
            return;
        }
        this.targetInput = input;
        this.customTextButtonTarget.classList.toggle('d-none', !showCustomTextButton);
        this.componentId = componentId;
        this.modal.show();
    };
    hideModalCleanup = () => {
        this.selectedTextblockIds = [];
        for (const button of this.element.querySelectorAll('button[data-id]')) {
            this.updateSelection(button);
        }
        for (const accordion of this.element.querySelectorAll('.accordion-collapse.show')) {
            new Collapse(accordion).hide();
        }
        this.updatepasteButton();
    };
    focusInput = () => {
        this.targetInput.focus();
    };
    clickSelectButton = (e) => {
        const button = e.currentTarget;
        if (this.selectedTextblockIds.includes(button.dataset.id)) {
            this.selectedTextblockIds = this.selectedTextblockIds.filter(id => id != button.dataset.id);
        }
        else {
            this.selectedTextblockIds.push(button.dataset.id);
        }
        this.updateSelection(button);
        this.updatepasteButton();
    };
    updatepasteButton = () => {
        this.updateCounter();
        this.pasteButtonTarget.disabled = this.selectedTextblockIds.length < 1;
    };
    updateSelection = async (button) => {
        const accordionButton = button
            .closest('.accordion-item')
            ?.querySelector('.accordion-button');
        if (!accordionButton) {
            return;
        }
        const selected = ` (${await translate('label.selected', 'base')})`;
        const buttonText = button.querySelector('span');
        const buttonImage = button.querySelector('svg');
        if (this.selectedTextblockIds.includes(button.dataset.id)) {
            accordionButton.innerText = accordionButton.innerText += selected;
            buttonText.innerText = await translate('label.selected', 'base');
            button.classList.add('selected');
            buttonImage.classList.remove('d-none');
        }
        else {
            if (accordionButton.innerText.includes(selected)) {
                accordionButton.innerText = accordionButton.innerText.replace(selected, '');
            }
            buttonText.innerText = await translate('label.select', 'base');
            button.classList.remove('selected');
            buttonImage.classList.add('d-none');
        }
    };
    updateCounter = () => {
        if (this.selectedTextblockIds.length < 1) {
            this.counterTarget.innerText = '';
        }
        else {
            this.counterTarget.innerText = `(${this.selectedTextblockIds.length.toString()})`;
        }
    };
    clickPasteButton = () => {
        const froalaEditorDomElement = document.getElementById(this.targetInput.id);
        const froalaEditor = froalaEditorDomElement['data-froala.editor'];
        const workflowForm = document.querySelector('[data-identifier="workflow-main-form"]');
        if (workflowForm) {
            let extraInput = document.querySelector('#workflow-text-element-map');
            if (!extraInput) {
                extraInput = document.createElement('input');
                extraInput.id = 'workflow-text-element-map';
                extraInput.type = 'text';
                extraInput.hidden = true;
                extraInput.name = 'workflow_text_element_map';
            }
            for (const block of this.selectedTextblockIds) {
                const textElementId = block.split('-')[1];
                if (extraInput.value.includes(textElementId)) {
                    continue;
                }
                if (extraInput.value === '') {
                    extraInput.value += textElementId;
                }
                else {
                    extraInput.value += ',' + textElementId;
                }
            }
            workflowForm.appendChild(extraInput);
        }
        const content = this.textblockTargets
            .filter(block => this.selectedTextblockIds.includes(block.dataset.id))
            .map(block => (froalaEditor ? block.innerHTML : block.innerText))
            .join(' \n');
        if (froalaEditor) {
            froalaEditor.html.set(froalaEditor.html.get() + content);
        }
        else {
            this.targetInput.value += content;
            this.targetInput.dispatchEvent(new Event('input'));
        }
        this.modal.hide();
    };
    abortCreation = () => {
        if (this.componentId) {
            this.collectionOutlet.removeComponentById(this.componentId);
        }
    };
}
