import TomSelect from 'tom-select';
import iconChevronDown from '/public/icons/chevron-down-black.svg';
import iconTick from '/public/icons/tick-black.svg';
import iconIndeterminate from '/public/icons/minus-black.svg';
TomSelect.define('sbb-multiselect', function () {
    this.require('no_active_items');
    this.require('checkbox_options');
    const sbbCheckbox = `<label class="sbb-checkbox-label">${iconTick}<div class="icon indeterminate">${iconIndeterminate}</div></label>`;
    this.settings.wrapperClass = 'ts-wrapper checkboxes';
    this.settings.render = {
        ...this.settings.render,
        optgroup_header: function (data, escape) {
            return `<div class="d-flex fw-bold position-relative" data-optgroup-header data-selectable><input type="checkbox"/>${sbbCheckbox}<div class="w-100 optgroup-header">${escape(data.label)}</div>${iconChevronDown}</div>`;
        },
        option: function (data, escape) {
            return `<div class="position-relative">${sbbCheckbox}<div class="w-100">${escape(data.text)}</div></div>`;
        },
    };
    this.closedGroups = [];
    function handleDropdown(multiselect) {
        const dropdown = multiselect.dropdown;
        if (!dropdown) {
            return;
        }
        for (const optGroup of dropdown.querySelectorAll('.optgroup')) {
            const optGroupHeader = optGroup.querySelector('[data-optgroup-header]');
            const dropdownArrow = optGroup.querySelector('[data-optgroup-dropdown]');
            const optGroupCheckbox = optGroup.querySelector('input[type="checkbox"]');
            if (multiselect.closedGroups.find((i) => i === optGroup.dataset.group)) {
                optGroup.classList.add('closed');
            }
            setHeaderCheckboxValueFromChildren(optGroupCheckbox);
            optGroupHeader.addEventListener('click', () => {
                optGroupCheckbox.click();
            });
            optGroupCheckbox.addEventListener('click', (e) => {
                toggleOptgroup(multiselect, optGroup, optGroupCheckbox);
                e.stopImmediatePropagation();
            });
            dropdownArrow.addEventListener('click', (e) => {
                optGroup.classList.toggle('closed');
                if (optGroup.classList.contains('closed')) {
                    multiselect.closedGroups.push(optGroup.dataset.group);
                }
                else {
                    multiselect.closedGroups.splice(multiselect.closedGroups.indexOf(optGroup.dataset.group), 1);
                }
                e.stopImmediatePropagation();
            });
        }
    }
    function toggleOptgroup(multiselect, optgroup, checkbox) {
        if (checkbox.checked) {
            multiselect.addItems(Array.from(optgroup.querySelectorAll('.option')).map(opt => opt.dataset.value));
        }
        else {
            for (const opt of Array.from(optgroup.querySelectorAll('.option')).map(opt => opt.dataset.value)) {
                multiselect.removeItem(opt);
            }
        }
    }
    function setHeaderCheckboxValueFromChildren(checkbox) {
        const options = Array.from(checkbox.parentElement.parentElement.querySelectorAll('.option'));
        checkbox.checked = options.some(c => c.classList.contains('selected'));
        checkbox.indeterminate = checkbox.checked && options.some(c => !c.classList.contains('selected'));
    }
    this.hook('after', 'render', () => {
        handleDropdown(this);
    });
    this.hook('after', 'close', () => {
        this.closedGroups = [];
    });
});
