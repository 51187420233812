import AbstractController from './abstract/AbstractController';
export default class extends AbstractController {
    radioChange = (event) => {
        const radioInput = event.target.querySelector('input[type="radio"]');
        if (radioInput === null) {
            return;
        }
        radioInput.click();
    };
}
