import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['format', 'screenPreview', 'aspectRatio'];
    toggleVideoFormatCheckState() {
        if (!(this.formatTarget && this.screenPreviewTarget && this.screenPreviewTarget)) {
            return;
        }
        this.formatTarget.checked = !this.formatTarget.checked;
        this.screenPreviewTarget.classList.toggle('-checked', this.formatTarget.checked);
        this.aspectRatioTarget.classList.toggle('-checked', this.formatTarget.checked);
    }
}
