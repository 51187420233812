import FroalaEditor from "froala-editor";
import * as bootstrap from 'bootstrap';
import { initialize } from "@intersim/froala-comments-plugin";
import "@intersim/froala-comments-plugin/dist/index.css";
initialize(FroalaEditor, bootstrap, {
    endpoints: {
        createComment: '/froala/comment/new',
        deleteComment: '/froala/comment/delete/',
        fetchThread: '/froala/comment/',
        fetchThreadOverview: '/froala/comment/threads',
        deleteThread: '/froala/comment/delete/thread/',
        deleteAllThreads: '/froala/comment/delete/threads/'
    },
    translations: {
        removeCommentConfirmation: 'Bist du sicher, dass du diesen Kommentar löschen möchtest?',
        answers: 'Antworten',
        noThreadsYet: 'Noch keine Kommentare vorhanden.',
        insertComment: 'Dein Kommentar...',
        comment: 'Kommentar',
        commandName: 'Kommentar hinzufügen',
        noTextWasSelected: 'Bitte wähle den zu kommentierenden Text aus.',
        noOverlappingCommentsAllowed: 'Kommentarstellen dürfen nicht überlappen.',
        noScriptsAllowed: 'Bitte überprüfe deine Eingabe.',
        addComment: 'Speichern',
        closeModal: 'Schliessen',
        commentHistory: 'Kommentarverlauf',
        removeThreadConfirmation: 'Bist du sicher, dass du diesen Kommentarverlauf löschen möchtest?',
    },
    styles: {
        threadHeight: 300,
        threadWidth: 600,
    },
    options: {
        commentPreviewLength: 100
    },
    commentator: null
});
