import TomSelect from 'tom-select';
import Sortable from 'sortablejs';
TomSelect.define('sortable', function () {
    if (this.settings.mode !== 'multi') {
        return;
    }
    // stop propagation of re-rendered items
    this.hook('after', 'render', () => {
        if (this.control) {
            const items = this.control.querySelectorAll('[data-value]');
            items.forEach((item) => {
                item.addEventListener('mousedown', e => {
                    e.stopPropagation();
                });
            });
        }
    });
    this.hook('after', 'setup', () => {
        new Sortable(this.control, {
            draggable: '[data-value]',
            filter: 'input',
            animation: 150,
            direction: 'horizontal',
            onEnd: () => {
                const values = Array.from(this.control.querySelectorAll('[data-value]')).map(item => item.dataset.value);
                this.setValue(values);
            },
        });
    });
});
