import ModalController from './abstract/ModalController';
import { hideErrors, showError } from '../helpers/form-helper';
import translate from '../helpers/translation-helper';
export default class default_1 extends ModalController {
    static outlets = ['frontify-finder-help'];
    connect = () => {
        for (const button of this.element.querySelectorAll('button[data-bs-dismiss=modal]')) {
            button.addEventListener('click', this.resetForm);
        }
        const frontifyModal = document.querySelector('#frontifyModal');
        frontifyModal?.addEventListener('hide.bs.modal', () => {
            this.modal.show();
        });
        this.submitButton = this.element.querySelector('button[type=submit]');
        if (!this.submitButton) {
            return;
        }
        this.submitButton.type = 'button';
        this.submitButton.addEventListener('click', this.validateForm);
    };
    resetForm = () => {
        const parentInput = this.element.querySelector('[name="create[parent]"]');
        parentInput?.tomselect?.clear();
        const titleInput = this.element.querySelector('[name="create[title]"]');
        if (!titleInput) {
            return;
        }
        titleInput.value = '';
        this.frontifyFinderHelpOutlet.removeImage();
    };
    validateForm = async () => {
        hideErrors(this.element);
        let hasErrors = false;
        const titleInput = this.element.querySelector('[name="create[title]"]');
        if (!titleInput) {
            return;
        }
        if (titleInput.value === '') {
            showError(titleInput, await translate('workflow.validation.required', 'admin'));
            hasErrors = true;
        }
        const imageInput = this.element.querySelector('[name="create[backgroundImageUrl]"]');
        if (!imageInput) {
            return;
        }
        if (imageInput.value === '') {
            showError(imageInput, await translate('workflow.validation.required', 'admin'));
            hasErrors = true;
        }
        if (!this.submitButton || hasErrors) {
            return;
        }
        this.submitButton.type = 'submit';
        this.submitButton.click();
    };
}
