import AbstractController from '../abstract/AbstractController';
import { DateTime } from 'luxon';
import { initTooltips } from '../../helpers/tooltip-helper';
export default class default_1 extends AbstractController {
    static targets = ['dateFilter', 'exportBtn'];
    connect() {
        initTooltips(this.element);
    }
    submitFormAsRequest = async () => {
        if (this.dateFilterTarget._flatpickr === undefined) {
            return;
        }
        const from = DateTime.fromJSDate(this.dateFilterTarget._flatpickr.selectedDates[0]);
        const to = DateTime.fromJSDate(this.dateFilterTarget._flatpickr.selectedDates[1]);
        if (!(from?.isValid && to?.isValid)) {
            return;
        }
        window.location.href = '/admin/exportDashboard?' + new URLSearchParams({
            date_from: from.toString(),
            date_to: to.toString(),
        });
    };
}
