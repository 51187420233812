import AbstractController from './abstract/AbstractController';
export default class extends AbstractController {
    changeMainCheckbox = (event) => {
        const subChannels = this.element.querySelectorAll('.sub-channel');
        const mainChannel = event.target;
        Array.from(subChannels).map(channel => {
            channel.disabled = !mainChannel.checked;
            if (!mainChannel.checked) {
                channel.checked = false;
            }
        });
    };
}
