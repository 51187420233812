import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['count', 'input'];
    maxCount = 0;
    connect = () => {
        const maxlength = this.inputTarget.getAttribute('maxlength');
        if (maxlength) {
            this.maxCount = parseInt(maxlength);
        }
        this.changeCounter();
    };
    changeCounter = () => {
        if (this.inputTarget instanceof HTMLParagraphElement) {
            this.countTarget.innerText = `${this.maxCount - this.inputTarget.innerText.length}`;
        }
        else {
            this.countTarget.innerText = `${this.maxCount - this.inputTarget.value.length}`;
        }
    };
    clear = () => {
        if (this.inputTarget instanceof HTMLParagraphElement) {
            this.inputTarget.innerText = '';
        }
        else {
            this.inputTarget.value = '';
        }
        this.changeCounter();
    };
}
