import ModalController from './abstract/ModalController';
export default class extends ModalController {
    connect = () => {
        const commentInputField = document.querySelector('[id$="fcp-reply"]');
        if (!commentInputField) {
            return;
        }
        this.enterBlockerElements = [commentInputField];
    };
}
