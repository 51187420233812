import tippy from 'tippy.js';
import AbstractController from './abstract/AbstractController';
export default class extends AbstractController {
    initialize = () => {
        tippy(this.element, {
            theme: 'sbb',
            appendTo: 'parent',
        });
    };
}
