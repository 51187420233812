import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['upButton'];
    footerOffset = 0;
    connect = () => {
        const footerElement = document.querySelector('footer');
        if (footerElement) {
            this.footerOffset = footerElement.offsetHeight;
        }
        this.hideUpButtonIfNoVerticalSpace();
        window.addEventListener('scroll', () => this.checkScroll());
    };
    checkScroll = () => {
        if (!this.upButtonTarget)
            return;
        const isScrolledToTop = window.scrollY <= 250;
        this.upButtonTarget.classList.toggle('d-none', isScrolledToTop);
    };
    scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    hideUpButtonIfNoVerticalSpace = () => {
        if (document.documentElement.scrollHeight >= window.innerHeight) {
            this.upButtonTarget.classList.add('d-none');
        }
    };
}
