export function passesFilter(input, filters) {
    const filter = filters.reduce((a, b) => input => a(input) && b(input));
    return filter(input);
}
export const getFilterType = (filter) => {
    if (filter.tomselect) {
        return 'select';
    }
    else if (filter._flatpickr) {
        return 'date';
    }
    else if (filter instanceof HTMLInputElement) {
        return 'text';
    }
    else {
        return 'unknown';
    }
};
export const getFilterValue = (filter) => {
    switch (getFilterType(filter)) {
        case 'select':
            return filter.tomselect?.getValue();
        case 'date':
            return filter._flatpickr.selectedDates;
        default:
            return filter.value;
    }
};
export const clearFilter = (filter) => {
    switch (getFilterType(filter)) {
        case 'select':
            filter.tomselect?.clear();
            break;
        case 'date':
            if (filter.dataset.defaultDate) {
                filter._flatpickr.setDate(filter.dataset.defaultDate.split(','));
            }
            else {
                filter._flatpickr.clear();
            }
            break;
        default:
            filter.value = '';
            filter.dispatchEvent(new Event('input'));
    }
};
