import AbstractController from './abstract/AbstractController';
import tippy from 'tippy.js';
export default class default_1 extends AbstractController {
    static targets = ['screenPreview', 'widthInput', 'heightInput'];
    connect = () => {
        this.errorWidthTippy = tippy(this.widthInputTarget, {
            theme: 'sbb',
            appendTo: () => document.body,
            placement: 'top-end',
            onHide() {
                return false;
            },
        });
        this.errorHeightTippy = tippy(this.heightInputTarget, {
            theme: 'sbb',
            appendTo: () => document.body,
            placement: 'top-start',
            onHide() {
                return false;
            },
        });
        this.errorWidthTippy.disable();
        this.errorHeightTippy.disable();
        if (this.widthInputTarget.hasAttribute('data-tippy-content')) {
            this.errorWidthTippy.enable();
            this.errorWidthTippy.show();
        }
        if (this.heightInputTarget.hasAttribute('data-tippy-content')) {
            this.errorHeightTippy.enable();
            this.errorHeightTippy.show();
        }
    };
    updateScreenPreview() {
        const height = parseFloat(this.heightInputTarget.value);
        const width = parseFloat(this.widthInputTarget.value);
        // @ts-ignore
        if (height === 'NaN' || height <= 0 || width === 'NaN' || width <= 0) {
            return;
        }
        const long_side_length = 64;
        const short_side_length = (long_side_length * Math.min(width, height)) / Math.max(width, height);
        const target_width = width > height ? long_side_length : short_side_length;
        const target_height = width > height ? short_side_length : long_side_length;
        this.screenPreviewTarget.style.height = target_height + 'px';
        this.screenPreviewTarget.style.width = target_width + 'px';
        this.screenPreviewTarget.innerHTML = '';
        this.screenPreviewTarget.classList.remove('border-0');
    }
}
