// @ts-ignore
import { Toast } from 'bootstrap';
export const checkForNotifications = () => {
    const urlParams = new URLSearchParams(window.location.search);
    checkForSuccessNotifications(urlParams);
};
const checkForSuccessNotifications = (urlParams) => {
    if (urlParams.get('success') === '1') {
        const toast = getNotificationToast('save-success');
        if (!toast) {
            return;
        }
        toast.show();
        urlParams.delete('success');
    }
    cleanUrl();
};
export const getNotificationToast = (selector, parentElement = document) => {
    const elem = parentElement.querySelector(`[data-notification=${selector}]`);
    if (!elem) {
        return null;
    }
    return new Toast(elem);
};
export const navigateWithSuccess = (path) => {
    const newLocation = window.location;
    if (path) {
        newLocation.pathname = path;
    }
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.append('success', '1');
    newLocation.search = urlParams.toString();
    window.location = newLocation;
};
const cleanUrl = () => {
    window.history.replaceState(null, '', window.location.pathname);
};
