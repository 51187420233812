import AbstractController from './abstract/AbstractController';
import { create } from '@frontify/frontify-finder';
import { Modal, Toast } from 'bootstrap';
import { checkAssetAccess } from '../helpers/frontify-helper';
import { DateTime } from 'luxon';
import { getNotificationToast } from '../helpers/notification-helper';
import translate from '../helpers/translation-helper';
export default class default_1 extends AbstractController {
    static targets = [
        'imageWrapper',
        'fileNameWrapper',
        'assetWrapper',
        'assetJpg',
        'assetWebp',
        'finderButton',
        'imageWorkflowButton',
        'fallbackImageButton',
    ];
    modal;
    headerImageAltTextField = undefined;
    headerImageCroppingField = undefined;
    headerImageValueField = null;
    noLicenseWarningNotification;
    internalLicenseWarningNotification;
    expirationWarningNotification;
    connect = () => {
        this.headerImageAltTextField = document.querySelector('[data-header-image-field="altText"]');
        this.headerImageCroppingField = document.querySelector('[data-header-image-field="cropping"]');
        this.noLicenseWarningNotification = getNotificationToast('header-image-no-license-warning');
        this.internalLicenseWarningNotification = getNotificationToast('header-image-internal-license-warning');
        this.expirationWarningNotification = getNotificationToast('header-image-expiration-warning');
    };
    chooseFallback = () => {
        this.getValueField();
        if (this.element.dataset.frontifyFallbackImageId && this.headerImageValueField) {
            this.headerImageValueField.value = this.element.dataset.frontifyFallbackImageId;
            if (this.element.dataset.frontifyFallbackImagePreview) {
                const previewURL = new URL(this.element.dataset.frontifyFallbackImagePreview);
                previewURL.searchParams.append('width', '400');
                previewURL.searchParams.append('format', 'webp');
                this.assetWebpTarget.srcset = previewURL.toString();
            }
            this.imageWrapperTarget.classList.remove('d-none');
            this.assetWrapperTarget.classList.remove('d-none');
            this.fallbackImageButtonTarget.classList.add('d-none');
        }
    };
    openFinder = async () => {
        const modalElement = document.getElementById('frontifyModal');
        this.modal = Modal.getOrCreateInstance('#frontifyModal');
        this.modal.hide();
        if (this.modal === null || modalElement === null) {
            return;
        }
        const iframe = modalElement.querySelector('iframe');
        if (iframe !== null) {
            iframe.remove();
        }
        const finder = await create({
            clientId: 'client-z9mkcbf9tr6c6qff',
            domain: 'brand.sbb.ch',
            options: {
                allowMultiSelect: false,
            },
        }).catch(() => {
            this.finderButtonTarget.classList.add('d-none');
            this.imageWorkflowButtonTarget?.classList.add('d-none');
            this.fallbackImageButtonTarget.classList.remove('d-none');
            return null;
        });
        if (!finder) {
            return;
        }
        this.modal.show();
        finder.onAssetsChosen(async (assets) => {
            this.getValueField();
            const path = this.element.dataset.checkAccessLink;
            if (path === '' || path === undefined) {
                return false;
            }
            if (!checkAssetAccess(assets, path)) {
                const warningToast = new Toast('#frontifyModal .toast.-warning');
                warningToast.show();
                return;
            }
            const firstAsset = assets[0];
            // @ts-ignore stimulus this.has[Name]Target
            if (!firstAsset || !firstAsset.previewUrl || !this.hasAssetWebpTarget || !this.hasAssetJpgTarget) {
                return;
            }
            this.fileNameWrapperTarget.innerText = firstAsset.filename;
            if (this.headerImageValueField === null) {
                return;
            }
            const dataHeaderImageLicences = document.querySelector('[data-header-image-licences]');
            if (dataHeaderImageLicences) {
                dataHeaderImageLicences.innerHTML =
                    //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
                    firstAsset.licenses.length > 0
                        ? (await translate('frontify.licence_label', 'base')) +
                            ': ' +
                            //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
                            firstAsset.licenses?.map(l => l.title).join(', ')
                        : 'Keine Lizenz hinterlegt';
            }
            const dataHeaderImageExpiresOn = document.querySelector('[data-header-image-expires-on]');
            if (dataHeaderImageExpiresOn) {
                dataHeaderImageExpiresOn.innerHTML = firstAsset.expiresAt
                    ? DateTime.fromISO(firstAsset.expiresAt).toFormat('dd.LL.yyyy')
                    : 'Kein Ablaufdatum';
            }
            const dataHeaderPhotographer = document.querySelector('[data-header-image-photographer]');
            if (dataHeaderPhotographer) {
                dataHeaderPhotographer.innerHTML =
                    firstAsset.author !== '' ? 'Foto: ' + firstAsset.author : 'Kein Fotograf hinterlegt';
            }
            this.noLicenseWarningNotification?.hide();
            this.internalLicenseWarningNotification?.hide();
            this.expirationWarningNotification?.hide();
            //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
            if (firstAsset.licenses.length < 1 || firstAsset.licenses.find(l => l.title === 'Keine Lizenz')) {
                this.noLicenseWarningNotification?.show();
            }
            //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
            if (firstAsset.licenses.length > 0 && firstAsset.licenses.find(l => l.title === 'Intern')) {
                this.internalLicenseWarningNotification?.show();
            }
            if (firstAsset.expiresAt && DateTime.fromISO(firstAsset.expiresAt).diffNow().valueOf() < 0) {
                this.expirationWarningNotification?.show();
            }
            this.headerImageValueField.value = firstAsset.id;
            this.headerImageAltTextField?.classList.remove('visually-hidden');
            this.headerImageCroppingField?.classList.remove('visually-hidden');
            this.imageWrapperTarget.classList.remove('d-none');
            this.assetWrapperTarget.classList.remove('d-none');
            const previewURL = new URL(firstAsset.previewUrl);
            previewURL.searchParams.append('width', '1920');
            previewURL.searchParams.append('format', 'webp');
            this.assetWebpTarget.srcset = previewURL.toString();
            const previewURLFallback = new URL(firstAsset.previewUrl);
            previewURLFallback.searchParams.append('width', '1920');
            previewURLFallback.searchParams.append('format', 'jpg');
            this.assetJpgTarget.src = previewURLFallback.toString();
            this.finderButtonTarget.classList.add('d-none');
            this.imageWorkflowButtonTarget?.classList.add('d-none');
            this.modal?.hide();
        });
        finder.onCancel(() => {
            this.modal?.hide();
        });
        const finderWrapper = document.querySelector('.frontify-finder-wrapper');
        if (finderWrapper === null) {
            return;
        }
        finder.mount(finderWrapper);
    };
    removeImage = () => {
        if (this.element.dataset.sceneIndex) {
            this.headerImageValueField = document.querySelector(`[data-header-image-field="value-${this.element.dataset.sceneIndex}"]`);
        }
        else {
            this.headerImageValueField = document.querySelector('[data-header-image-field="value"]');
        }
        this.finderButtonTarget.classList.remove('d-none');
        this.imageWorkflowButtonTarget?.classList.remove('d-none');
        this.imageWrapperTarget.classList.add('d-none');
        this.headerImageAltTextField?.classList.add('visually-hidden');
        this.headerImageCroppingField?.classList.add('visually-hidden');
        if (this.headerImageValueField === null) {
            return;
        }
        this.headerImageValueField.value = '';
        this.assetWrapperTarget.classList.add('d-none');
        this.noLicenseWarningNotification?.hide();
        this.internalLicenseWarningNotification?.hide();
        this.expirationWarningNotification?.hide();
    };
    getValueField = () => {
        if (this.element.dataset.sceneIndex) {
            this.headerImageValueField = document.querySelector(`[data-header-image-field="value-${this.element.dataset.sceneIndex}"]`);
        }
        else {
            this.headerImageValueField = document.querySelector('[data-header-image-field="value"]');
        }
    };
}
