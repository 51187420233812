import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['sceneTitle', 'sceneTitleInput', 'sceneTitleEditIcon', 'imageWorkflowButton'];
    connect = () => {
        this.sceneTitleEditIconTarget.addEventListener('click', () => {
            this.sceneTitleInputTarget.classList.remove('d-none');
            this.sceneTitleTarget.classList.add('d-none');
            this.sceneTitleEditIconTarget.classList.add('d-none');
            this.sceneTitleInputTarget.focus();
            this.sceneTitleInputTarget.value = this.sceneTitleTarget.innerText;
            this.sceneTitleInputTarget.addEventListener('blur', () => {
                this.sceneTitleInputTarget.classList.add('d-none');
                this.sceneTitleEditIconTarget.classList.remove('d-none');
                this.sceneTitleTarget.classList.remove('d-none');
                this.sceneTitleTarget.innerText = this.sceneTitleInputTarget.value;
            });
        });
        this.imageWorkflowButtonTarget.addEventListener('click', () => {
            this.saveIdToLocalStorage();
        });
    };
    saveIdToLocalStorage = () => {
        // Start from the div element and traverse up the DOM tree to find the first parent element with the attribute "data-scene-index" set
        let parentElement = this.imageWorkflowButtonTarget;
        while (parentElement) {
            if (parentElement.hasAttribute('data-scene-index')) {
                // Found the parent element with the attribute "data-scene-index" set
                break;
            }
            const tempParentElement = parentElement.parentElement;
            if (tempParentElement) {
                parentElement = tempParentElement;
            }
        }
        const currentSceneIndex = parentElement.getAttribute('data-scene-index');
        if (currentSceneIndex) {
            localStorage.setItem('current-scene-index', currentSceneIndex.toString());
        }
    };
}
