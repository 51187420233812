import { DateTime } from 'luxon';
import chevronLeftIcon from '/public/icons/chevron-left-black.svg';
import chevronRightIcon from '/public/icons/chevron-right-black.svg';
export default function sbbDatepickerPlugin() {
    return function (instance) {
        const initCustomHeader = () => {
            const customYearContainer = document.createElement('div');
            customYearContainer.classList.add('flatpickr-custom-years', 'position-absolute', 'end-0', 'd-flex', 'justify-content-center');
            const nextYearButton = document.createElement('button');
            nextYearButton.classList.add('next-year', 'border-0', 'bg-transparent', 'position-absolute', 'end-0');
            nextYearButton.innerHTML = chevronLeftIcon;
            const lastYearButton = document.createElement('button');
            lastYearButton.classList.add('last-year', 'border-0', 'bg-transparent', 'position-absolute', 'start-0');
            lastYearButton.innerHTML = chevronRightIcon;
            customYearContainer.append(lastYearButton, instance.currentYearElement.parentElement, nextYearButton);
            instance.monthNav.append(customYearContainer);
            for (const yearInputArrow of instance.currentYearElement.parentElement?.querySelectorAll('span') || []) {
                yearInputArrow.classList.add('d-none');
            }
            const monthSelect = instance.monthsDropdownContainer;
            monthSelect.disabled = monthSelect.childElementCount < 2;
            monthSelect.parentElement?.classList.toggle('disabled', monthSelect.childElementCount < 2);
            lastYearButton.addEventListener('click', () => {
                if (!instance) {
                    return;
                }
                instance.changeYear(instance.currentYear - 1);
            });
            nextYearButton.addEventListener('click', () => {
                if (!instance) {
                    return;
                }
                instance.changeYear(instance.currentYear + 1);
            });
            if (isNaN(Number.parseInt(instance.currentYearElement.max))) {
                instance.currentYearElement.max = '9999';
            }
            instance.currentYearElement.addEventListener('blur', e => onYearInputChange(e));
            instance.currentYearElement.addEventListener('input', e => onYearInputChange(e));
            toggleYearNavButtonVisibility();
        };
        const toggleYearNavButtonVisibility = () => {
            const nextYearButton = instance.calendarContainer.querySelector('button.next-year');
            const lastYearButton = instance.calendarContainer.querySelector('button.last-year');
            if (nextYearButton && lastYearButton) {
                nextYearButton.classList.toggle('invisible', instance.currentYear >= Number.parseInt(instance.currentYearElement.max));
                lastYearButton.classList.toggle('invisible', instance.currentYear <= Number.parseInt(instance.currentYearElement.min));
            }
        };
        const onYearInputChange = (e) => {
            if (!(e.target && e.target instanceof HTMLInputElement && instance)) {
                return;
            }
            const year = Number.parseInt(e.target.value);
            const minYear = Number.parseInt(instance.currentYearElement.min);
            const maxYear = Number.parseInt(instance.currentYearElement.max);
            if (e.type === 'blur' && isNaN(year)) {
                e.target.value = instance.currentYear.toString();
            }
            else if (e.type === 'blur' && !isNaN(minYear) && year < minYear) {
                instance.changeYear(minYear);
                e.target.value = minYear.toString();
            }
            else if (!isNaN(maxYear) && year > maxYear) {
                instance.changeYear(maxYear);
                e.target.value = maxYear.toString();
            }
        };
        const jumpToValidDate = () => {
            const date = DateTime.fromISO(instance.input.value);
            const minDate = instance.config.minDate ? DateTime.fromJSDate(instance.config.minDate) : null;
            const maxDate = instance.config.maxDate ? DateTime.fromJSDate(instance.config.maxDate) : null;
            if (maxDate && minDate && maxDate.diff(minDate).valueOf() < 0) {
                instance.config.disable.push(minDate.startOf('day').toJSDate());
                instance.clear();
                instance.jumpToDate(Date.now(), false);
            }
            else if (maxDate && date.diff(maxDate).valueOf() > 0) {
                instance.jumpToDate(maxDate.toJSDate(), false);
            }
            else if (minDate && date.diff(minDate).valueOf() < 0) {
                instance.jumpToDate(minDate.toJSDate(), false);
            }
        };
        const setPlaceholder = () => {
            if (!instance.altInput) {
                return;
            }
            let placeholder;
            switch (instance.config.mode) {
                case 'single':
                    placeholder = 'TT.MM.JJJJ mm:ss';
                    break;
                case 'time':
                    placeholder = 'mm:ss';
                    break;
                case 'range':
                    placeholder = 'TT.MM.JJJJ bis TT.MM.JJJJ';
                    break;
                case 'multiple':
                    placeholder = 'TT.MM.JJJJ';
                    break;
                default:
                    placeholder = 'TT.MM.JJJJ';
            }
            instance.altInput.placeholder = placeholder;
        };
        // year field should only be validated when the datepicker is open, as sometimes past dates are valid
        const disableNumInput = () => {
            const numInputs = Array.from(document.getElementsByClassName('cur-year'));
            numInputs.forEach(el => {
                el.setAttribute("disabled", "");
            });
        };
        const enableNumInput = () => {
            const numInputs = Array.from(document.getElementsByClassName('cur-year'));
            numInputs.forEach(el => {
                el.removeAttribute("disabled");
            });
        };
        return {
            onReady() {
                initCustomHeader();
                setPlaceholder();
                disableNumInput();
            },
            onYearChange() {
                toggleYearNavButtonVisibility();
            },
            onOpen() {
                jumpToValidDate();
                toggleYearNavButtonVisibility();
                enableNumInput();
            },
        };
    };
}
