import AbstractController from './abstract/AbstractController';
import MentionService, { ArrowHandlingOption } from '@intersim/mention-field';
export default class default_1 extends AbstractController {
    static targets = ['textfield', 'suggestionList'];
    mentionService;
    connect = () => {
        const config = {
            suggestionRequestUrl: window.location.origin + '/autocomplete/user_autocomplete_field',
        };
        this.mentionService = new MentionService(this.textfieldTarget, this.suggestionListTarget, config);
    };
    checkFieldLength = (event) => {
        if (event.key.length !== 1) {
            return;
        }
        const maxlength = this.textfieldTarget.getAttribute('maxlength');
        if (maxlength !== null && this.textfieldTarget.innerText.length >= +maxlength) {
            event.preventDefault();
        }
    };
    handleTextChange = (event) => {
        this.mentionService?.handleTextChange(event);
    };
    selectSuggestion = (event) => {
        this.mentionService?.selectSuggestion(event);
    };
    selectNextSuggestion = () => {
        this.mentionService?.changeActiveSuggestion(this.mentionService?.getRequestedSuggestion(ArrowHandlingOption.Next));
    };
    selectPreviousSuggestion = () => {
        this.mentionService?.changeActiveSuggestion(this.mentionService?.getRequestedSuggestion(ArrowHandlingOption.Previous));
    };
}
