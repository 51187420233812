import AbstractController from './abstract/AbstractController';
import tippy from 'tippy.js';
import translate from '../helpers/translation-helper';
export default class default_1 extends AbstractController {
    static targets = ['openPopoverButton', 'realButton'];
    localTippy = null;
    connect = async () => {
        this.localTippy = tippy(this.openPopoverButtonTarget, {
            theme: 'sbb',
            content: '<div class="d-flex flex-column">' +
                '<p class="m-0">' +
                (await translate('label.sure', 'base')) +
                '</p>' +
                '<div class="d-flex">' +
                '<button ' +
                this.element.dataset.deleteAction +
                ' class="sbb-button m-0 mb-1 mt-1 primary medium" type="button">' +
                (await translate('label.delete', 'base')) +
                '</button>' +
                '<button type="button" data-action="click->delete-popover#clickCancelButton" class="sbb-button secondary mb-1 mt-1 enabled-click medium">' +
                (await translate('label.cancel', 'base')) +
                '</button>' +
                '</div>' +
                '</div>',
            allowHTML: true,
            trigger: 'click',
            interactive: true,
        });
        if (!this.hasRealButtonTarget) {
            return;
        }
        const observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
                    this.openPopoverButtonTarget.disabled = this.realButtonTarget.disabled;
                }
            }
        });
        // Start observing the button
        observer.observe(this.realButtonTarget, { attributes: true });
    };
    clickRealButton = () => {
        this.realButtonTarget.click();
    };
    clickCancelButton = () => {
        if (this.localTippy) {
            this.localTippy.hide();
        }
    };
}
