import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['img', 'src', 'radioButton'];
    connect = () => {
        this.radioButtonTargets.forEach(target => {
            target.addEventListener('change', e => {
                if (e.currentTarget instanceof HTMLInputElement) {
                    this.imgTarget.style.objectPosition = this.convertPosition(e.currentTarget.value);
                    this.srcTarget.style.objectPosition = this.convertPosition(e.currentTarget.value);
                }
            });
        });
    };
    convertPosition = (input) => {
        const positionMap = {
            c: 'center',
            l: 'left',
            r: 'right',
            t: 'top',
            b: 'bottom',
        };
        let result = '';
        for (const char of input) {
            if (positionMap[char]) {
                result += positionMap[char] + ' ';
            }
            else {
                result += 'Invalid ';
            }
        }
        return result.trim();
    };
}
